import {
  ref,
  getStorage,
  uploadBytes,
  connectStorageEmulator,
  getDownloadURL,
  getMetadata,
  deleteObject,
} from "firebase/storage";
import { getApp } from "@firebase/app";
import { bucketNames } from "../Utils/constants";
const switchBucket = (bucketName) => {
  if (process.env.REACT_APP_STAGING === "local") {
    connectStorageEmulator(getStorage(getApp(), bucketName), "localhost", 9199);
  }
  return getStorage(getApp(), bucketName);
};

export async function getFileUrl(storageLocation) {
  const slashElementIndex = storageLocation.indexOf("/", 12);
  const fileName = storageLocation.substring(
    slashElementIndex + 1,
    storageLocation.length
  );
  const bucketName = storageLocation.substring(0, slashElementIndex);
  const customStorage = switchBucket(bucketName);
  const fileRef = ref(customStorage, fileName);
  const url = await getDownloadURL(fileRef);
  const metaData = await getMetadata(fileRef);

  return { url: url, type: metaData.contentType.split("/")[0] };
}

export async function uploadCompliantProof(
  file,
  complaintId,
  locationId,
  type
) {
  const customStorage = switchBucket(bucketNames.defaultBucket);
  await uploadBytes(
    ref(
      customStorage,
      `/${locationId}/complaints/${complaintId}/${type}/${file?.name}`
    ),
    file
  );
  return file?.name;
}

export async function uploadProcedureHistoryFiles(filePath, file) {
  const customStorage = switchBucket(bucketNames.defaultBucket);
  await uploadBytes(ref(customStorage, filePath), file);
  return `${customStorage._url}${filePath}`;
}

export async function deleteDocumentStorage(fileURL) {
  const slashElementIndex = fileURL.indexOf("/", 12);
  const fileName = fileURL.substring(slashElementIndex + 1, fileURL.length);
  const bucketName = fileURL.substring(0, slashElementIndex);
  const customStorage = switchBucket(bucketName);
  const removeDocument = ref(customStorage, fileName);
  // Delete the file
  try {
    await deleteObject(removeDocument);
    return removeDocument;
  } catch (error) {
    console.error(error);
  }
}

export async function uploadReferencePicture(file, fileName) {
  const customStorage = switchBucket(bucketNames.surveillanceBucket);
  const filePath = `/reference/${fileName}`;
  await uploadBytes(ref(customStorage, filePath), file);
  return `${customStorage._url}${filePath}`;
}
