import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Button from "../../Components/Button/Button";
import { AddIcon, CloseIcon, ProcedureEditIcon } from "../../Assets/assets";
import { setErrorStatus } from "../../Redux/status/action";
import { createPicture } from "../../Redux/surveillance/action";
import InputBox from "../../Components/InputBox/InputBox";
import Modal from "../../Components/Modal/Modal";
import { isValidObject } from "../../Services/validators";
import { dateAndTimeConverter } from "../../Utils/constants";

function Surveillance(props) {
  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "+91",
  });
  const [image, setImage] = useState(null);
  const [showModal, setShowModal] = useState("getEmployee");
  const [employeeReferenceId, setEmployeeReferenceId] = useState(null);
  const [employeeData, setEmployeeData] = useState(null);

  const [captures, setCaptures] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    if (!isValidObject(props.surveillance.captures)) {
      return;
    }

    const _captures = Object.values(props.surveillance.captures).filter(
      (data) => data.pictureId === employeeReferenceId
    );

    setCaptures(_captures);
  }, [props.surveillance, employeeReferenceId]);

  useEffect(() => {
    if (!formData?.phoneNumber) {
      return;
    }

    const referenceDoc = Object.values(props.surveillance.reference)?.find(
      (data) => data.employee?.phoneNumber === formData?.phoneNumber
    );

    if (isValidObject(referenceDoc)) {
      setEmployeeReferenceId(referenceDoc.documentId);
      // setFormData(null);
      setShowModal(null);
    }
    // eslint-disable-next-line
  }, [props.surveillance.reference]);

  const handleSubmit = (event) => {
    event.preventDefault();
    // const image = event.target.files[0];

    if (image) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const arrayBuffer = reader.result;
        const fileBlob = new Blob([arrayBuffer], { type: image.type });

        props.createPicture(
          { name: formData.name, employee: employeeData },
          fileBlob
        );
      };

      reader.readAsArrayBuffer(image);
      setImage(null);
    }
  };

  const isValidEmployeePhoneNumber = (phoneNumber) => {
    if (!(phoneNumber?.length === 13 || phoneNumber === "+91")) {
      return {
        status: false,
        message: "Please enter valid phone number",
      };
    } else if (
      phoneNumber.length === 13 &&
      !Object.values(props.employees.data).some(
        (data) => data.phoneNumber === phoneNumber
      )
    ) {
      return {
        status: false,
        message: "Employee not exist",
      };
    } else {
      return {
        status: true,
        message: "",
      };
    }
  };

  return (
    <div className="remaining-body-height-from-header ">
      {employeeReferenceId && (
        <div
          style={{
            position: "absolute",
            top: "98px",
          }}
          className="height-fit-content width-fit-content position-absolute z-index-1 bottom-32px left-32px"
        >
          <InfoCard
            data={formData}
            onEditClick={() => {
              setFormData({ name: "", phoneNumber: "+91" });
              setEmployeeReferenceId(null);
              setEmployeeData(null);
              setShowModal("getEmployee");
            }}
          />
        </div>
      )}

      <div className=" inherit-parent-width display-flex flex-direction-column  flex-align-items-center overflow-scroll ">
        {captures
          ?.sort((first, second) => {
            return second.createdAt - first.createdAt;
          })
          .map((data) => (
            <div
              style={{
                width: "500px",
                height: "100px",
                "background-color":
                  props.theme === "dark" ? "#434343" : "#f2f2f2",
              }}
              className="  margin-top-default margin-bottom-default display-flex bac"
            >
              <img
                src={props.surveillance?.reference?.[data.pictureId]?.fileSrc}
                alt="reference"
                height={100}
                width={100}
                onClick={() => {
                  setPreviewImage(
                    props.surveillance?.reference?.[data.pictureId]?.fileSrc
                  );
                }}
              />
              <div
                style={{ width: "300px", height: "100px" }}
                className=" padding-default font-size-default"
              >
                <div> Name: {data.name} </div>
                <div>
                  Location: {data.location.PCTNo}-{data.location.address}{" "}
                </div>
                <div>
                  Date: {dateAndTimeConverter(data.createdAt, "secondDate")}{" "}
                </div>
                <div>
                  {" "}
                  Time: {dateAndTimeConverter(data.createdAt, "Time")}{" "}
                </div>
              </div>
              <img
                src={data.fileSrc}
                alt="capture"
                height={100}
                width={100}
                onClick={() => {
                  setPreviewImage(data.fileSrc);
                }}
              />
            </div>
          ))}

        <Modal
          show={typeof previewImage === "string"}
          canIgnore={true}
          onClose={() => {
            setPreviewImage(null);
          }}
          borderRadius="false"
          boxShadow="false"
        >
          <div
            className="display-flex flex-justify-content-center"
            data-cy={"view-image-modal"}
          >
            <img src={previewImage} height={500} alt="preview" />
          </div>
        </Modal>
        {document.addEventListener("keydown", function(event) {
          if (event.key === "Escape" && previewImage !== null) {
            setPreviewImage(null);
          }
        })}
      </div>

      <Modal
        show={showModal === "getEmployee"}
        canIgnore={false}
        onClose={() => {
          // setShowModal(false);
        }}
        width="inherit-parent-width"
        maxWidth="max-width-528px"
        boxShadow="none"
        minWidth="none"
        background="background-transparent"
        height="height-fit-to-content"
      >
        <div
          style={{ minWidth: "fit-content" }}
          className={`${
            props.auth?.theme === "dark"
              ? "background-color-dark"
              : "background-white"
          } width-80-percent border-radius-default`}
        >
          <form
            className="display-flex flex-direction-column padding-larger"
            onChange={(event) => {
              setFormData({
                ...formData,
                [event.target.name]: event.target.value,
              });
            }}
            autoComplete="off"
            onSubmit={(event) => {
              event.preventDefault();
              const referenceDoc = Object.values(
                props.surveillance.reference
              ).find(
                (data) => data.employee?.phoneNumber === formData?.phoneNumber
              );

              if (isValidObject(referenceDoc)) {
                setFormData({
                  ...formData,
                  name: referenceDoc.name,
                });
                setEmployeeReferenceId(referenceDoc.documentId);
                setShowModal(null);
              } else {
                const _employeeData = Object.values(props.employees.data).find(
                  (data) => data.phoneNumber === formData?.phoneNumber
                );
                setFormData({
                  ...formData,
                  name: _employeeData.name,
                });
                setEmployeeData(_employeeData);
                setShowModal("uploadPicture");
              }
            }}
          >
            <div
              className={`font-size-medium text-align-center  ${
                props.auth?.theme === "dark" ? "font-color-white" : ""
              } `}
            >
              PROVIDE DETAILS TO GET EMPLOYEE CAPTURES
            </div>
            <InputBox
              className="inherit-parent-width padding-top-larger"
              name="phoneNumber"
              label="PHONE NUMBER"
              type="phoneNumber"
              removeResponsive={true}
              autoComplete="off"
              theme={props.auth?.theme}
              fontColor={props.auth?.theme === "dark" ? "font-color-white" : ""}
              labelFontColor={
                props.auth?.theme === "dark" ? "font-color-white" : ""
              }
              value={formData?.phoneNumber}
              data-cy="phoneNumber-field"
              required
              validation={(value) => {
                return isValidEmployeePhoneNumber(value);
              }}
            />

            <Button
              data-cy={
                props.track?.loading ? "track-loading-button" : "track-button"
              }
              loading={props.track?.loading}
              type="submit"
              className="margin-top-larger"
              text="Submit"
              disabled={
                !isValidEmployeePhoneNumber(formData?.phoneNumber).status ||
                formData?.phoneNumber === "+91"
              }
              boxShadow={false}
            />
          </form>
        </div>
      </Modal>

      <Modal
        show={showModal === "uploadPicture"}
        canIgnore={false}
        onClose={() => {
          // setShowModal(false);
        }}
        width="inherit-parent-width"
        maxWidth="max-width-528px"
        boxShadow="none"
        minWidth="none"
        background="background-transparent"
        height="height-fit-to-content"
      >
        <div
          style={{ minWidth: "fit-content" }}
          className={`${
            props.auth?.theme === "dark"
              ? "background-color-dark"
              : "background-white"
          } width-80-percent border-radius-default padding-larger`}
        >
          <form
            className=" flex-justify-content-center  flex-align-items-center flex-direction-column"
            onChange={(event) => {
              if (event.target.files) {
                if (
                  ["image/jpeg", "image/jpg"].includes(
                    event.target.files[0]?.type
                  ) === false
                ) {
                  return setErrorStatus({
                    code: "custom",
                    message: "Kindly upload a valid File",
                  });
                }

                if (
                  ["image/jpeg", "image/jpg"].includes(
                    event.target.files[0]?.type
                  ) === true
                ) {
                  setImage(event.target.files[0]);
                  setFile(URL.createObjectURL(event.target.files[0]));
                }
              } else {
                setFormData({
                  ...formData,
                  [event.target.name]: event.target.value,
                });
              }
            }}
            onSubmit={(event) => {
              handleSubmit(event);
            }}
          >
            <div className=" padding-bottom-larger">Add Picture</div>

            {file ? (
              <div
                className=" display-flex position-relative "
                style={{ left: "10px" }}
              >
                <img
                  src={file}
                  alt={"selected"}
                  style={{ height: "100px", width: "100px" }}
                />
                <div
                  className=" position-relative height-fit-content background-color-404040 border-radius-50-percentage flex-justify-content-center padding-small cursor-pointer"
                  style={{ right: "20px" }}
                  onClick={() => {
                    setFile(null);
                  }}
                >
                  <CloseIcon width="12" height="12" />
                </div>
              </div>
            ) : (
              <label>
                <input
                  type="file"
                  accept=".jpg,.jpeg"
                  name={"fileUploaderInput"}
                  onClick={(event) => {
                    event.target.value = "";
                  }}
                  className="visibility-hidden position-absolute"
                />

                <div
                  className=" background-color-f2f2f2  display-flex flex-justify-content-center flex-align-items-center border-radius-default padding-top"
                  style={{ height: "100px", width: "100px" }}
                  data-cy="file-upload-icon"
                >
                  <AddIcon />
                </div>
              </label>
            )}
            <InputBox
              name="name"
              type="text"
              removeResponsive={true}
              label="Name"
              className="padding-top-large  "
              value={formData?.name}
              fontColor={props.theme === "dark" ? "font-color-white" : ""}
              labelFontColor={props.theme === "dark" ? "font-color-white" : ""}
              disabledBackgroundColor={
                props.theme === "dark" ? "background-color-404040" : null
              }
              disabled={true}
            />

            <Button
              boxShadow={false}
              type="submit"
              variant="primary"
              text="Upload photo"
              className={" "}
              loading={props.surveillance.loading}
              disabled={image === null}
              data-cy={"upload-button"}
            />
          </form>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  surveillance: state.surveillance,
  auth: state.auth,
  employees: state.employees,
});

const mapDispatchToProps = function() {
  return {
    createPicture: (data, asset) => createPicture(data, asset),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Surveillance);

function InfoCard(props) {
  return (
    <div
      data-cy="location-tracker-info-card"
      style={{
        width: "400px",
      }}
      className="display-flex info-card-box-shadow background-color-404040 font-color-white flex-direction-column flex-align-items-center  font-size-larger padding-large border-radius-16px"
      onClick={props.onEditClick}
    >
      <div className="font-size-medium padding-bottom-large">
        EMPLOYEE INFORMATION
      </div>
      <div className="display-flex inherit-parent-width flex-align-items-center flex-justify-content-space-between">
        <div className="display-flex flex-direction-column">
          <div>Name: {props.data?.name}</div>
          <div className=" padding-top-default">
            Phone number: {props.data?.phoneNumber}
          </div>
        </div>
        <div>
          <ProcedureEditIcon color="white" />
        </div>
      </div>
    </div>
  );
}
