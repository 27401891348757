import { all, put, takeEvery } from "@redux-saga/core/effects";

export const actionTypes = {
  PUT_ALGOLIA_DATA: "PUT_ALGOLIA_DATA",
  CLEAR_ALGOLIA_DATA: "CLEAR_ALGOLIA_DATA",
};

function* getAlgoliaDataWorker(action) {
  yield put({
    type: "SET_ALGOLIA_QUERY",
    payload: { data: action.payload.data },
  });
}

function* clearAlgoliaDataWorker() {
  yield put({
    type: "RESET_ALGOLIA_DATA",
  });
}

export default function* algoliaWatcher() {
  yield all([takeEvery("PUT_ALGOLIA_DATA", getAlgoliaDataWorker)]);
  yield all([takeEvery("CLEAR_ALGOLIA_DATA", clearAlgoliaDataWorker)]);
}
