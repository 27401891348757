import { all } from "redux-saga/effects";
import authWatcher from "./authentication/saga";
import profileWatcher from "./profile/saga";
import statusWatcher from "./status/saga";
import complaintsWatcher from "./complaints/saga";
import proceduresWatcher from "./procedures/saga";
import employeesWatcher from "./employees/saga";
import sensorsWatcher from "./sensors/saga";
import algoliaWatcher from "./algolia/saga";
import locationsWatcher from "./locations/saga";
import surveillanceWatcher from "./surveillance/saga";
import tableWatcher from "./table/saga";
import tracksWatcher from "./track/saga";

export default function* rootSaga() {
  yield all([
    algoliaWatcher(),
    authWatcher(),
    profileWatcher(),
    statusWatcher(),
    complaintsWatcher(),
    tracksWatcher(),
    locationsWatcher(),
    proceduresWatcher(),
    employeesWatcher(),
    sensorsWatcher(),
    surveillanceWatcher(),
    tableWatcher(),
  ]);
}
