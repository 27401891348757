import React from "react";
import "./TrackProgress.css";
import { dateAndTimeConverter } from "../../Utils/constants";
import CarouselAndAudioPlayer from "../CarouselAndAudioPlayer/CarouselAndAudioPlayer";
import { isValidArray, isValidObject } from "../../Services/validators";

export default function TrackProgress(props) {
  return (
    <div>
      {props.data?.map((data, index) => (
        <>
          <div className="display-flex flex-align-items-center">
            <div className="width-fit-content height-fit-content">
              <div
                className={`${
                  typeof data?.success === "boolean"
                    ? data?.success
                      ? "background-color-green"
                      : "background-color-red"
                    : "background-white"
                }`}
                style={{
                  height: "12px",
                  width: "12px",
                }}
                data-cy={
                  typeof data?.success === "boolean"
                    ? `${data?.title}-success-${
                        data?.success ? "green" : "red"
                      }-icon`
                    : "white-icon"
                }
              />
              {index !== props.data.length - 1 ? (
                <div
                  style={{
                    height: "115px",
                    paddingRight: "2px",
                  }}
                  className="width-16px flex-align-items-center flex-justify-content-center"
                >
                  <div
                    style={{ width: "2px" }}
                    className={`inherit-parent-height ${
                      props.theme === "dark"
                        ? "white-dashed-border"
                        : "black-dashed-border"
                    }`}
                  />
                </div>
              ) : (
                <div style={{ height: "115px" }} />
              )}
            </div>
            <div className="display-flex inherit-parent-width">
              <div
                style={{ minWidth: "60%" }}
                className="display-flex flex-direction-column width-70-percentage "
              >
                <div
                  className="padding-left-large text-uppercase font-size-medium "
                  data-cy={data?.title || data?.remarks || "N/A"}
                >
                  {data?.title || data?.remarks || "N/A"}
                </div>

                {data.description && (
                  <div
                    className="padding-left-large padding-top-default font-size-small text-overflow-ellipsis"
                    data-cy={data.description}
                  >
                    {data.description}
                  </div>
                )}
                {isValidObject(data.kpi) && (
                  <div className="padding-left-large padding-top-default font-size-small"
                  data-cy = {`${data?.title}-KPI-${data.kpi.initial}`}
                  >
                    KPI:{" "} {data.kpi.initial}
                  </div>)}
                {isValidObject(data.penalty) && (
                  <div className="padding-left-large padding-top-default font-size-small"
                  data-cy = {`${data?.title}-PENALTY-${data.penalty.cost}`}
                  >
                    PENALTY:{" "}  {data.penalty.cost}
                  </div>)}


                {isValidArray(data.requiredRemarks) &&
                  data.requiredRemarks.map((data) => (
                    <div className="padding-left-large padding-top-default text-uppercase font-size-small">
                      {data.value || ""}
                    </div>
                  ))}

                {data.updatedAt && (
                  <div
                    className="padding-left-large padding-top-small padding-bottom-default font-size-small"
                    data-cy={dateAndTimeConverter(data.updatedAt, "cardDate")}
                  >
                    {`${dateAndTimeConverter(
                      data.updatedAt,
                      "Time"
                    )} , ${dateAndTimeConverter(data.updatedAt, "cardDate")}`}
                  </div>
                )}
              </div>
              <div>
                <CarouselAndAudioPlayer
                  dataCy={data?.title}
                  fileSrc={data?.assets}
                  theme={props.theme}
                  onImageClick={(data) => {
                    props.setPreview(data);
                  }}
                />
              </div>
              <div></div>
            </div>
          </div>

          {data.description && !(index === props.data.length - 1) && (
            <div className="display-flex">
              <div className="width-16px flex-align-items-center flex-justify-content-center">
                <div
                  style={{ width: "2px", height: "9px" }}
                  className={`${
                    props.theme === "dark"
                      ? "white-dashed-border"
                      : "black-dashed-border"
                  }`}
                />
              </div>
              <div className="padding-left-large padding-top-large"></div>
            </div>
          )}
        </>
      ))}
    </div>
  );
}
