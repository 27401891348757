import React, { useState } from "react";
import "./ToolTip.css";

export default function ToolTip(props) {
  const [showMessage, setShowMessage] = useState(false);
  console.log(props?.text);
  return (
    <div
      className={` position-relative display-flex`}
      data-cy={`${props.dataCy ? props.dataCy : "input"}-tool-tip`}
      onMouseEnter={() => {
        setShowMessage(true);
      }}
      onMouseLeave={() => {
        setShowMessage(false);
      }}
    >
      {props?.children}
      {showMessage && props?.text && (
        <span
          className={`${
            props.position ? props.position : "top"
          } background-color-e2e2e2-dark font-size-small font-color-black white-space-no-wrap text-align-center padding-default width-fit-content`}
          style={{
            borderRadius: "4px",
          }}
        >
          {props?.text}
        </span>
      )}
    </div>
  );
}
