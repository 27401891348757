import React, { useState, useEffect } from "react";
import ControlButton from "../ControlButton/ControlButton";
import { dateAndTimeConverter, openInNewTab } from "../../Utils/constants";
import "../../index.css";
import { setErrorStatus } from "../../Redux/status/action";
import { isValidArray, isValidObject } from "../../Services/validators";
import { ErrorRoundIcon, InfoIcon, TickRoundIcon } from "../../Assets/assets";
import CustomScrollBar from "../CustomScrollBar/CustomScrollBar";
import {
  getPictureStatus,
  getReferencePictureAsset,
  getSelectedEmployeeCapture,
} from "../../Redux/surveillance/action";
import Button from "../Button/Button";

export default function EmployeesPreview(props) {
  const [activeOption, setActiveOption] = useState("MANAGE");
  const [hover, setHover] = useState(false);
  const [tempSelectedRoles, setTempSelectedRoles] = useState(props.employeeData?.roles || []);
  const [pictureFailureCount, setPictureFailureCount] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [getPictureStatusIntervalId, setGetPictureStatusIntervalId] = useState(null);

  const retryInterval = 1000 * 60 * 30; // 30 mins

  useEffect(() => {
    if (!isValidObject(props.devices) || !isValidObject(props.status)) return;

    let count = Object.keys(props.devices).length - Object.keys(props.status).length;

    Object.values(props.status).forEach(data => {
      if (data.success === false) count += 1;
    });

    setPictureFailureCount(count);
  }, [props.devices, props.status]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const validFileTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (!validFileTypes.includes(file.type)) {
      setErrorStatus({ code: "custom", message: "Kindly upload a valid File" });
    } else if (file.size > 250000) {
      setErrorStatus({ code: "input/file-size-exceeded", message: "Document size should be less than 5MB" });
    } else {
      setSelectedFile({ file, previewUrl: URL.createObjectURL(file) });
    }
  };

  const handlePictureUpload = async () => {
    if (!selectedFile) return;

    try {
      const fileBlob = await uploadFile(selectedFile.file);
      if (props.referencePictureData?.asset) {
        props.editPicture({
          employee: {
            ...(props.employeeData.type === "admin" ? { email: props.employeeData.email } : { phoneNumber: props.employeeData.phoneNumber }),
            name: props.employeeData.name,
            id: props.employeeData.employeeId,
          },
        }, fileBlob, props.referencePictureData?.documentId);
      } else {
        props.createPicture({
          employee: {
            ...(props.employeeData.type === "admin" ? { email: props.employeeData.email } : { phoneNumber: props.employeeData.phoneNumber }),
            name: props.employeeData.name,
            id: props.employeeData.employeeId,
          },
        }, fileBlob);
      }
      setSelectedFile(null);
    } catch (error) {
      console.error("Error uploading file:", error);
      setErrorStatus({ code: "upload_error", message: "There was an error uploading the file." });
    }
  };

  const handleRoleClick = (roleId) => {
    setTempSelectedRoles(prevRoles => prevRoles.includes(roleId)
      ? prevRoles.filter(role => role !== roleId)
      : [...prevRoles, roleId]
    );
  };

  const handleSaveRoles = () => {
    props.addRolesToSelectedEmployee(props.employeeData.employeeId, tempSelectedRoles);
  };

  const hasChanges = () => {
    const rolesChanged = JSON.stringify(props.employeeData?.roles || []) !== JSON.stringify(tempSelectedRoles);
    const fileChanged = !!selectedFile;
    return rolesChanged || fileChanged;
  };

  useEffect(() => {
    if (activeOption === "CAPTURES") getSelectedEmployeeCapture(props.employeeData.employeeId);
    if (activeOption === "MANAGE") getReferencePictureAsset(props.employeeData?.employeeId);
    setSelectedFile(null);
  }, [activeOption, props.employeeData?.employeeId]);

  useEffect(() => {
    if (props.employeeData?.employeeId && activeOption === "MANAGE") {
      clearInterval(getPictureStatusIntervalId);
      const intervalId = setInterval(() => getPictureStatus(props.employeeData?.employeeId), 5000);
      setGetPictureStatusIntervalId(intervalId);
    } else {
      clearInterval(getPictureStatusIntervalId);
      setGetPictureStatusIntervalId(null);
    }

    return () => clearInterval(getPictureStatusIntervalId);
    // eslint-disable-next-line 
  }, [props.employeeData?.employeeId, activeOption]);

  return (
    <div data-cy="location-modal" className="modal-frame background-color-dark inherit-parent-height inherit-parent-width display-flex padding-larger min-width-170px">
      {/* Header-section */}
      <div className="inherit-parent-height padding-right-medium display-flex flex-direction-column" data-cy="header-section">
        {["MANAGE", props.claims?.surveillance?.read && "CAPTURES", "ROLES"].filter(Boolean).map((header, index) => (
          <ControlButton
            key={index}
            text={header}
            selected={activeOption === header}
            onClick={() => setActiveOption(header)}
            dataCy={activeOption === header ? `header-selected-${header.toLowerCase()}` : `header-select-${header.toLowerCase()}`}
          />
        ))}
      </div>

      {/* Body-section */}
      <div className="parent-width-sub-156px inherit-parent-height display-flex flex-direction-column padding-left-medium font-size-default position-relative" data-cy="employees-body-section" style={{ marginLeft: "48px" }}>

        <CustomScrollBar horizontal="left">
          <div className="flex-direction-column flex-justify-content-space-between">
            <div className="inherit-parent-width padding-top-default padding-bottom-default margin-bottom-medium text-align-left display-flex flex-direction-column position-sticky top-0 background-color-dark">
              <div className="font-size-larger text-uppercase padding-bottom-default" data-cy={props.employeeData?.name || "N/A"}>
                {props.employeeData?.name || "N/A"}
              </div>
              <div className="font-size-default text-uppercase padding-bottom-default" data-cy={props.employeeData?.phoneNumber || "N/A"}>
                {props.employeeData?.type === "admin" ? props.employeeData?.email : props.employeeData?.phoneNumber || "N/A"}
              </div>
            </div>

            {/* Active Option Views */}
            {activeOption === "MANAGE" && (
              <ManageView
                employeeData={props.employeeData}
                selectedFile={selectedFile}
                referencePictureData={props.referencePictureData}
                pictureFailureCount={pictureFailureCount}
                hover={hover}
                setHover={setHover}
                handleFileChange={handleFileChange}
                retryInterval={retryInterval}
                surveillanceRetryLoading={props.surveillanceRetryLoading}
                retryPictureUploadToDevices={props.retryPictureUploadToDevices}
              />
            )}

            {activeOption === "CAPTURES" && (
              <CapturesView
                capturePictureData={props.capturePictureData}
                surveillanceLoading={props.surveillanceLoading}
              />
            )}

            {activeOption === "ROLES" && (
              <RolesView
                roles={props.roles}
                employeeData={props.employeeData}
                tempSelectedRoles={tempSelectedRoles}
                handleRoleClick={handleRoleClick}
              />
            )}
          </div>


        </CustomScrollBar>
        <div className="inherit-parent-width flex-justify-content-end">
          <Button
            boxShadow={false}
            type="button"
            variant="primary"
            text="UPDATE"
            disabled={!hasChanges()}
            className="margin-right-small width-200px margin-top-large"
            data-cy="update-button"
            onClick={activeOption === "MANAGE" ? handlePictureUpload : handleSaveRoles}
          />
        </div>
      </div>
    </div>
  );
}

function ManageView(props) {
  return (
    <div className="inherit-parent-height inherit-parent-width display-flex flex-direction-column font-size-default" data-cy="employee-manage-section">
      {[
        { title: "NAME", value: props.employeeData?.name || "N/A" },
        { title: "CONTACT", value: props.employeeData?.type === "admin" ? props.employeeData?.email : props.employeeData?.phoneNumber || "N/A" },
        {
          title: "PROFILE",
          value: (
            <ProfilePictureForm
              selectedFile={props.selectedFile}
              fileSrc={props.referencePictureData?.fileSrc}
              pictureFailureCount={props.pictureFailureCount}
              hover={props.hover}
              setHover={props.setHover}
              handleFileChange={props.handleFileChange}
            />
          ),
        },
      ].filter(Boolean).map((data, index) => (
        <Fields key={index} title={data.title} data={data.value} />
      ))}

      {props.pictureFailureCount !== 0 && props.pictureFailureCount !== null && (
        <div className="display-flex flex-justify-content-space-between padding-top-large">
          <div className="display-flex flex-align-items-center">
            <InfoIcon color="#FFFFFF" />
            <div className="padding-left-default">
              Profile updates are not reflected in <span>{props.pictureFailureCount}</span> location{props.pictureFailureCount > 1 ? "s" : ""};
              {(props.referencePictureData?.lastAppliedAt + props.retryInterval < +new Date() || !props.referencePictureData?.lastAppliedAt) && <span> reload to update</span>}
            </div>
          </div>
          {(props.referencePictureData?.lastAppliedAt + props.retryInterval < +new Date() || !props.referencePictureData?.lastAppliedAt) && (
            <div className="display-flex flex-align-items-center">
              {props.surveillanceRetryLoading && <div className="export-loader-dark margin-right-default" style={{ height: "12px", width: "12px" }} />}
              <div className={`cursor-pointer border-bottom-1px-e5e5e5 ${props.surveillanceRetryLoading ? "font-color-secondary" : ""}`} onClick={() => !props.surveillanceRetryLoading && props.retryPictureUploadToDevices(props.referencePictureData?.documentId)}>
                RELOAD
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function CapturesView({ capturePictureData, surveillanceLoading }) {
  if (surveillanceLoading) {
    return <CaptureSuspense />;
  }

  return isValidArray(capturePictureData) ? (
    capturePictureData.map((data, index) => (
      <CaptureItem key={index} data={data} index={index} />
    ))
  ) : (
    <div className="display-flex inherit-parent-height inherit-parent-width flex-justify-content-center flex-align-items-center">
      NO CAPTURE DATA FOUND
    </div>
  );
}

function RolesView(props) {
  return (
    <div className="inherit-parent-width" data-cy="location-moreInfo-option" >
      <div className="width-50-percentage">
        <Fields
          title="ROLES"
          data={
            <div>
              {Object.values(props.roles)?.filter(role => role?.type === props.employeeData?.type)?.map((role, index) => (
                <RoleItem key={index} role={role} tempSelectedRoles={props.tempSelectedRoles} handleRoleClick={props.handleRoleClick} />
              ))}
            </div>
          }
        />
      </div>
    </div>
  );
}

function RoleItem({ role, tempSelectedRoles, handleRoleClick }) {
  return (
    <div className="padding-left-large padding-bottom-default display-flex flex-justify-content-space-between" style={{ width: "352px" }}>
      <div>{role.name}</div>
      <div className="cursor-pointer" onClick={() => handleRoleClick(role.documentId)} >
        {tempSelectedRoles.includes(role.documentId) ? <div data-cy={`selected-role-${role.name}`} ><SelectedSquare /> </div> : <div data-cy={`select-role-${role.name}`}> <SelectSquare  /> </div>}
      </div>
    </div>
  );
}

function CaptureItem({ data, index }) {
  return (
    <div className={`${index !== 0 ? "margin-top-large inherit-parent-width" : ""}`} style={{ maxHeight: "96px" }}>
      <div className="display-flex inherit-parent-width">
        <div className="padding-right-large cursor-pointer" onClick={() => openInNewTab(data.fileSrc)}>
          <img loading="lazy" style={{ width: "192px", height: "96px" }} src={data.fileSrc} alt="captureImage" />
        </div>
        <div className="display-flex flex-justify-content-space-between flex-direction-column padding-top-default padding-bottom-default" style={{ minWidth: "65%", maxHeight: "96px" }}>
          <div>{data.location.address}</div>
          <div>PCT - {data.location.PCTNo}, WARD - {data.location.ward}, ZONE - {data.location.zone}</div>
          <div>{dateAndTimeConverter(data.createdAt, "Time")}, {dateAndTimeConverter(data.createdAt, "cardDate") || "N/A"}</div>
        </div>
      </div>
    </div>
  );
}

function Fields({ title, data }) {
  return (
    <div className="display-flex flex-justify-content-space-between padding-vertical-medium">
      <div className="display-flex">
        <div className="white-space-no-wrap" style={{ width: "180px" }} data-cy={`field-title-${title}`}>{title}</div>
        <div className="padding-horizontal-medium">:</div>
      </div>
      <div className="text-align-left inherit-parent-width white-space-no-wrap text-uppercase" data-cy={`field-value-${data}`}>{data}</div>
    </div>
  );
}

async function uploadFile(file) {
  const reader = new FileReader();
  const fileBlob = await new Promise((resolve, reject) => {
    reader.onloadend = () => {
      if (reader.error) reject(reader.error);
      const blob = new Blob([reader.result], { type: file.type });
      resolve(blob);
    };
    reader.readAsArrayBuffer(file);
  });
  return fileBlob;
}

const ProfilePictureForm = (props) => {
  return (
    <div>
      {props.fileSrc || isValidObject(props.selectedFile) ? (
        <form
          style={{ width: "96px", height: "96px" }}
          onMouseEnter={() => props.setHover(true)}
          onMouseLeave={() => props.setHover(false)}
          className="cursor-pointer position-relative"
          onChange={props.handleFileChange}
          data-cy="upload-picture"
        >
          <label>
            <input
              type="file"
              accept=".jpg,.jpeg,.png"
              name="fileUploaderInput"
              onClick={(event) => (event.target.value = "")}
              className="visibility-hidden position-absolute"
               
            />
            <img style={{ width: "96px", height: "96px" }} src={isValidObject(props.selectedFile) ? props.selectedFile.previewUrl : props.fileSrc} alt="profileImage" />

            {props.hover && (
              <div style={{ position: "absolute", opacity: "85%", top: "0px" }}>
                <DefaultEditPic />
              </div>
            )}

            <div className="position-absolute" style={{ bottom: "-10px", right: "-8px" }}>
              {props.pictureFailureCount === 0 ? <TickRoundIcon /> : <ErrorRoundIcon />}
            </div>
          </label>
        </form>
      ) : (
        <form style={{ width: "96px", height: "96px" }} className="margin-bottom-default cursor-pointer" onChange={props.handleFileChange}>
          <label>
            <input
              type="file"
              accept=".jpg,.jpeg,.png"
              name="fileUploaderInput"
              onClick={(event) => (event.target.value = "")}
              className="visibility-hidden position-absolute"
            />
            <DefaultAddPic />
          </label>
        </form>
      )}
    </div>
  )
}
const CaptureSuspense = () => (
  <section className="padding-top-large inherit-parent-width" data-cy="notification-suspense">
    {[...Array(10)].map((_, index) => (
      <div key={index} className="margin-top-large display-flex margin-bottom-larger inherit-parent-width">
        <div>
          <div style={{ width: "96px", height: "96px" }} className="shimmer-dark padding-large"></div>
        </div>
        <div className="inherit-parent-width padding-left-large">
          <div style={{ width: "10%" }} className="shimmer-dark padding-default margin-bottom-medium"></div>
          <div style={{ width: "40%" }} className="shimmer-dark padding-default margin-bottom-medium"></div>
          <div style={{ width: "20%" }} className="shimmer-dark padding-default margin-bottom-larger"></div>
        </div>
      </div>
    ))}
  </section>
);
const DefaultAddPic = () => {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2615_703)">
        <rect width="96" height="96" fill="#262626" clipopacity="0.4" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.8 47.3V41H49.2V47.3H55.5V48.7H49.2V55H47.8V48.7H41.5V47.3H47.8Z"
          fill="white"
        />
        <path d="M6.94727 96H0.000629902V94.2633H6.94727V96Z" fill="white" />
        <path
          d="M1.73729 89.0534V96H0.000629902V89.0534H1.73729Z"
          fill="white"
        />
        <path
          d="M88.9473 95.9473H95.8939V94.2106H88.9473V95.9473Z"
          fill="white"
        />
        <path
          d="M94.1572 89.0006V95.9473H95.8939V89.0006H94.1572Z"
          fill="white"
        />
        <path d="M88.9473 0H95.8939V1.73666H88.9473V0Z" fill="white" />
        <path d="M94.1572 6.94664V0H95.8939V6.94664H94.1572Z" fill="white" />
        <path d="M6.94727 0H0.000629902V1.73666H6.94727V0Z" fill="white" />
        <path
          d="M1.73729 6.94664V0H0.000629902V6.94664H1.73729Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2615_703">
          <rect width="96" height="96" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
const DefaultEditPic = () => {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2615_703)">
        <rect width="96" height="96" fill="#262626" />
        <path
          d="M50.5588 42.7884L53.0294 45.2589M48.9118 54.3178H55.5M42.3235 51.0236L41.5 54.3178L44.7941 53.4942L54.3355 43.9528C54.6443 43.644 54.8178 43.2251 54.8178 42.7884C54.8178 42.3516 54.6443 41.9328 54.3355 41.6239L54.1939 41.4822C53.885 41.1735 53.4662 41 53.0294 41C52.5927 41 52.1738 41.1735 51.8649 41.4822L42.3235 51.0236Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M6.94727 96H0.000629902V94.2633H6.94727V96Z" fill="white" />
        <path
          d="M1.73729 89.0534V96H0.000629902V89.0534H1.73729Z"
          fill="white"
        />
        <path
          d="M88.9473 95.9473H95.8939V94.2106H88.9473V95.9473Z"
          fill="white"
        />
        <path
          d="M94.1572 89.0006V95.9473H95.8939V89.0006H94.1572Z"
          fill="white"
        />
        <path d="M88.9473 0H95.8939V1.73666H88.9473V0Z" fill="white" />
        <path d="M94.1572 6.94664V0H95.8939V6.94664H94.1572Z" fill="white" />
        <path d="M6.94727 0H0.000629902V1.73666H6.94727V0Z" fill="white" />
        <path
          d="M1.73729 6.94664V0H0.000629902V6.94664H1.73729Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2615_703">
          <rect width="96" height="96" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
const SelectedSquare = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="13" height="13" stroke="white" />
      <rect x="3.5" y="3.5" width="7" height="7" fill="white" />
      <rect x="3.5" y="3.5" width="7" height="7" stroke="white" />
    </svg>
  );
};
const SelectSquare = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="13" height="13" stroke="white" />
    </svg>
  );
};