import { put, takeEvery, all } from "redux-saga/effects";
import { isValidArray } from "../../Services/validators";
import { setErrorStatus } from "../status/action";
// import { setErrorStatus } from "../status/action";

export const tableActionTypes = {
  SET_TABLE_LOADING: "SET_TABLE_LOADING",
  SET_SELECTED_TABLE: "SET_SELECTED_TABLE",
  SET_ACTIVE_SWITCH: "SET_ACTIVE_SWITCH",
  SET_SHOW_MODAL: "SET_SHOW_MODAL",
  SET_SELECTED_TABLE_ID: "SET_SELECTED_TABLE_ID",
  SET_TABLE_FILTER: "SET_TABLE_FILTER",
};

function* setSelectedTableWorker(action) {
  yield put({
    type: "PUT_SELECTED_TABLE",
    payload: {
      selectedTable: action.payload.selectedTable,
    },
  });
}

function* setActiveSwitchWorker(action) {
  yield put({
    type: "PUT_ACTIVE_SWITCH",
    payload: {
      activeSwitch: action.payload.activeSwitch,
    },
  });
}

function* setShowModalWorker(action) {
  yield put({
    type: "PUT_SHOW_MODAL",
    payload: {
      data: action.payload.data,
    },
  });
}

function* setSelectedTableIdWorker(action) {
  if (action.payload.table === "LOCATIONS") {
    yield put({
      type: "PUT_SELECTED_LOCATION",
      payload: {
        id: action.payload.id,
      },
    });
  }

  if (action.payload.table === "COMPLAINTS") {
    yield put({
      type: "PUT_SELECTED_COMPLAINT",
      payload: {
        id: action.payload.id,
      },
    });
  }

  if (action.payload.table === "PROCEDURES") {
    yield put({
      type: "PUT_SELECTED_PROCEDURE",
      payload: {
        id: action.payload.id,
      },
    });
  }

  if (action.payload.table === "WORKFORCE") {
    yield put({
      type: "PUT_SELECTED_EMPLOYEE",
      payload: {
        id: action.payload.id,
      },
    });
  }
}

function* setTableFilterWorker(action) {
  if (action.payload.table === "LOCATIONS") {
    yield put({
      type: "PUT_LOCATIONS_FILTER",
      payload: {
        filter: action.payload.filter,
      },
    });
  }

  if (action.payload.table === "COMPLAINTS") {
    let filterLength = 1;

    Object.values(action.payload.filter).forEach((data) => {
      if (isValidArray(data)) {
        filterLength *= data.length;
      }
    });

    if (filterLength <= 20) {
      yield put({
        type: "PUT_COMPLAINTS_FILTER",
        payload: {
          filter: action.payload.filter,
        },
      });
    } else {
      setErrorStatus({ code: "custom", message: "Filter limit reached" });
    }
  }

  if (action.payload.table === "PROCEDURES") {
    let filterLength = 1;

    Object.values(action.payload.filter).forEach((data) => {
      if (isValidArray(data)) {
        filterLength *= data.length;
      }
    });

    if (filterLength <= 20) {
      yield put({
        type: "PUT_PROCEDURES_FILTER",
        payload: {
          filter: action.payload.filter,
        },
      });
    } else {
      setErrorStatus({ code: "custom", message: "Filter limit reached" });
    }
  }
}

export default function* tableWatcher() {
  yield all([takeEvery("SET_SELECTED_TABLE", setSelectedTableWorker)]);
  yield all([takeEvery("SET_ACTIVE_SWITCH", setActiveSwitchWorker)]);
  yield all([takeEvery("SET_SHOW_MODAL", setShowModalWorker)]);
  yield all([takeEvery("SET_SELECTED_TABLE_ID", setSelectedTableIdWorker)]);
  yield all([takeEvery("SET_TABLE_FILTER", setTableFilterWorker)]);
}

// function* setTableLoading(value) {
//   yield put({
//     type: "SET_TABLE_LOADING",
//     payload: {
//       loading: value,
//     },
//   });
// }
