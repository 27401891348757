import React from "react";
import "./ControlButton.css";
export default function ControlButton(props) {
  return (
    <div
      style={
        props.selected === false && props.disable
          ? {
              opacity: "50%",
            }
          : {}
      }
      className="position-relative margin-bottom-larger width-fit-content"
    >
      <div
        className={`display-flex flex-align-items-center inherit-parent-width text-uppercase border-1px-e5e5e5 padding-medium text-overflow-ellipsis font-size-larger cursor-pointer ${
          props.selected === true
            ? "selected-background-color-control-button font-color-white"
            : " background-color-dark"
        }  `}
        style={{
          height: "64px",
          width: "184px",
        }}
        onClick={() => {
          if (!props.disable) {
            props?.onClick();
          }
        }}
        data-cy={props?.dataCy}
      >
        <div className="corner top-left" />
        <div className="corner top-right" />
        <div className="corner bottom-left" />
        <div className="corner bottom-right" /> {props?.text}
      </div>
    </div>
  );
}
