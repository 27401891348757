export const stripeDoughnutChartPlugin = {
  id: "stripeDoughnutChartPlugin",
  afterDraw: (chart) => {
    if (chart.config.type !== "doughnut") return;
    const ctx = chart.ctx;
    const { chartArea } = chart;

    if (!chartArea) return;

    const stripeWidth = 4;
    const stripeSpacing = 1;
    const stripeColor = "#020D23";
    const angle = -45 * (Math.PI / 180);

    const totalWidth = chartArea.right - chartArea.left;
    const totalHeight = chartArea.bottom - chartArea.top;
    const stripeCount =
      Math.ceil(totalWidth / (stripeWidth + stripeSpacing)) + 20; // +20 to cover the diagonal

    ctx.save();
    ctx.fillStyle = stripeColor;

    // Translate to the center of the chart area
    const centerX = chartArea.left + totalWidth / 2;
    const centerY = chartArea.top + totalHeight / 2;
    ctx.translate(centerX, centerY);
    ctx.rotate(angle);

    // Draw stripes
    for (let i = -stripeCount; i < stripeCount; i++) {
      const x = i * (stripeWidth + stripeSpacing);
      ctx.fillRect(x, -totalHeight, stripeWidth, 2 * totalHeight);
    }

    ctx.restore();
  },
};

export const stripeBarChartPlugin = {
  id: "stripeBarChartPlugin",
  afterDraw: (chart) => {
    const ctx = chart.ctx;
    if (chart.config.type === "doughnut") return;

    const { chartArea } = chart;

    if (!chartArea) return;

    const stripeHeight = 4;
    const stripeSpacing = 1;
    const stripeColor = "#020D23";
    const leftOffset = 2;
    const bottomOffset = 2;

    const totalWidth = chartArea.right - chartArea.left;
    const totalHeight = chartArea.bottom - chartArea.top;
    const stripeCount = Math.ceil(totalHeight / (stripeHeight + stripeSpacing));

    ctx.save();
    ctx.fillStyle = stripeColor;

    for (let i = 0; i < stripeCount; i++) {
      const y = i * (stripeHeight + stripeSpacing);
      ctx.fillRect(
        chartArea.left + leftOffset,
        chartArea.top + y - bottomOffset,
        totalWidth,
        stripeHeight
      );
    }

    ctx.restore();
  },
};
