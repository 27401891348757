import { useEffect, useRef } from "react";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { isValidArray } from "./validators";
import greenMarker from "../Assets/greenMarker.svg";
import { darkModeStyle } from "../Utils/constants";

export default function useMap(
  locations,
  container,
  mapContainerRendered,
  isAuth,
  markerOnclick
) {
  const map = useRef({
    Map: null,
    loading: false,
  });
  const markers = useRef([]);
  const clustered = useRef(null);
  const isInitialized = useRef(false);
  async function loadMapsAndMarkers(mapContainer) {
    map.current.loading = true;
    const mapOptions = {
      center: {
        lat: 13.048129476915838,
        lng: 80.2305531172708,
      },
      zoom: 12,
      disableDefaultUI: true,
      minZoom: 8,
      styles: darkModeStyle,
    };

    // eslint-disable-next-line no-undef
    const { Map } = await google.maps.importLibrary("maps");

    map.current.Map = new Map(mapContainer, mapOptions);

    // eslint-disable-next-line no-unused-vars, no-undef
    const { Marker, AdvancedMarkerElement } = await google.maps.importLibrary(
      "marker"
    );
    map.current.loading = false;

    markLocations();
    isInitialized.current = true;
  }

  useEffect(() => {
    if (
      !map.current.Map &&
      map.current.loading === false &&
      isAuth === true &&
      mapContainerRendered === true
    ) {
      loadMapsAndMarkers(container.current);
    }
    // eslint-disable-next-line
  }, [locations, isAuth, mapContainerRendered]);

  useEffect(() => {
    if (isInitialized.current) {
      markLocations();
    }
    // eslint-disable-next-line
  }, [locations]);

  const markLocations = () => {
    if (isValidArray(markers.current)) {
      clustered.current.clearMarkers();
      markers.current.forEach((marker) => {
        marker.setMap(null);
      });
      markers.current = [];
    }

    if (isValidArray(markers.current)) {
      return;
    }
    // eslint-disable-next-line no-undef
    const infoWindow = new window.google.maps.InfoWindow();

    locations?.forEach((data) => {
      // eslint-disable-next-line no-undef
      const marker = new google.maps.Marker({
        position: {
          lat: data._geoloc?.lat,
          lng: data._geoloc?.lng,
        },
        title: `${data?.PCTNo?.toString()?.padStart(2, "0")} - ${
          data?.location
        }`,
        icon: greenMarker,
      });
      marker.setMap(map.current.Map);

      marker.addListener("click", () => {
        infoWindow.close();
        infoWindow.setContent(contents(data));
        infoWindow.open(map.current.Map, marker);
      });

      markers.current.push(marker);
    });

    new window.google.maps.event.addListener(infoWindow, "domready", () => {
      const customClickElement = document.getElementById("custom-click");
      if (customClickElement) {
        customClickElement.addEventListener("click", () => {
          markerOnclick(customClickElement.getAttribute("name"));
        });
      }
    });

    clustered.current = new MarkerClusterer({
      map: map.current.Map,
      markers: markers.current,
      renderer: {
        render({ count, position }) {
          const clusterSize = Math.min(60, 20 + count * 2);
          // eslint-disable-next-line no-undef
          return new google.maps.Marker({
            position,
            icon: {
              url: greenMarker,
              // eslint-disable-next-line no-undef
              scaledSize: new google.maps.Size(clusterSize, clusterSize),
            },
            // label: {
            //   text: String(count),
            //   color: "RED",
            //   fontSize: "16px",
            // },
          });
        },
      },
    });

    console.log("Markers are successfully loaded");
  };
}

const contents = (data) => {
  return `<div style="display:flex;font-family:aldrich-regular;">
      <div style="width: 4px; height: 4px; background-color: white; position: absolute; top: -1px; left: -1px;"></div>
      <div style="width: 4px; height: 4px; background-color: white; position: absolute; top: -1px; right: -1px;"></div>
      <div style="width: 4px; height: 4px; background-color: white; position: absolute; bottom: -1px; left: -1px;"></div>
      <div style="width: 4px; height: 4px; background-color: white; position: absolute; bottom: -1px; right: -1px;"></div>

      <div>
        <div style="height:100%;">
          <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="80" height="80" fill="#565656" fill-opacity="0.3" />
            <path
              d="M8 16V64H16V41.8947H26.0063H28V64H35.0094L44 16H8Z"
              fill="#909090"
            />
            <path
              d="M48 16L38.8936 64H52V42L64.4731 41.8947V64H72.9996V16H48Z"
              fill="#909090"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21.0114 30.5906C21.9082 30.5906 22.6353 29.852 22.6353 28.9409C22.6353 28.0298 21.9082 27.2912 21.0114 27.2912C20.1145 27.2912 19.3874 28.0298 19.3874 28.9409C19.3874 29.852 20.1145 30.5906 21.0114 30.5906ZM21.0114 32C22.6745 32 24.0227 30.6304 24.0227 28.9409C24.0227 27.2514 22.6745 25.8818 21.0114 25.8818C19.3482 25.8818 18 27.2514 18 28.9409C18 30.6304 19.3482 32 21.0114 32Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M23.2518 22.1131L28 22V26.6414L26.6126 26.6414V24.4256L23.0056 28.0988L22.0234 27.1034L25.5943 23.4671L23.2843 23.5221L23.2518 22.1131Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M62.1387 24.0693C62.1387 25.7645 60.7645 27.1387 59.0693 27.1387C57.3742 27.1387 56 25.7645 56 24.0693C56 22.3742 57.3742 21 59.0693 21C60.7645 21 62.1387 22.3742 62.1387 24.0693ZM59.0693 26.1156C60.1994 26.1156 61.1156 25.1994 61.1156 24.0693C61.1156 22.9392 60.1994 22.0231 59.0693 22.0231C57.9392 22.0231 57.0231 22.9392 57.0231 24.0693C57.0231 25.1994 57.9392 26.1156 59.0693 26.1156Z"
              fill="white"
            />
            <path
              d="M58.5578 26.6271H59.5809V33H58.5578V26.6271Z"
              fill="white"
            />
            <path
              d="M61.6271 29.1849V28.1618H56.5116V29.1849H61.6271Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
      <div style="padding-left:16px;width:100%">
        <div style="display:flex;justify-content:space-between;">
          <div style="font-size:14px;text-transform:uppercase;white-space:nowrap;padding-right:64px;">
            ${data?.location || "No ID Available"}
          </div>
          <div
            id="custom-click"
            name="${data.documentId}"
            style="cursor:pointer;"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1 1H9.6V2.2H2.2V13.8H13.8V6.4H15V15H1V1Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.8966 1.09712L14.8907 1.09712V5.09123H13.6907V3.18815L9.43822 7.61638L8.5727 6.7852L12.8827 2.29712L10.8966 2.29712V1.09712Z"
                fill="white"
              />
            </svg>
          </div>
        </div>

        <div style="padding-top:8px;font-size:10px;justify-content:space-between;display:flex;flex-direction:column;height:60px">
          <div style="">PCT : ${data?.PCTNo}</div>
          <div style="">ZONE : ${data?.zone}</div>
          <div style="">WARD : ${data?.ward}</div>
        </div>
      </div>
    </div>`;
};
