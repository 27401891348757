import store from "../store";
import { actionTypes } from "./saga";

export function putEmployeesData(data) {
  store.dispatch({
    type: actionTypes.PUT_EMPLOYEES_DATA,
    payload: { data: data },
  });
}

export function addCaptureSummaryData(data) {
  store.dispatch({
    type: actionTypes.ADD_CAPTURE_SUMMARY_DATA,
    payload: { data: data },
  });
}

export function getRolesData() {
  store.dispatch({
    type: actionTypes.GET_ROLES_DATA,
  });
}

export function addRolesToSelectedEmployee(employeeId, roles) {
  store.dispatch({
    type: actionTypes.ADD_ROLES_DATA,
    payload: { roles: roles, documentId: employeeId },
  });
}

export function createEmployee(data) {
  store.dispatch({
    type: actionTypes.CREATE_EMPLOYEE,
    payload: { data: data },
  });
}

export function assignProcedureToEmployee(employeeId, data, current) {
  store.dispatch({
    type: actionTypes.ASSIGN_PROCEDURE_TO_EMPLOYEE,
    payload: { data: data, employeeId: employeeId, current: current },
  });
}

export function deleteProcedureLinkedToEmployee(employeeId, data) {
  store.dispatch({
    type: actionTypes.DELETE_LINKED_PROCEDURE,
    payload: { data: data, employeeId: employeeId },
  });
}
