import store from "../store";
import { actionTypes } from "./saga";

/**
 * @module AlgoliaSaga
 */

/**
 *
 * @param {data} Object
 */
export function putAlgoliaData(data) {
  store.dispatch({
    type: actionTypes.PUT_ALGOLIA_DATA,
    payload: {
      data: data,
    },
  });
}

/**
 *
 * @param {data} Object
 */
export function clearAlgolia() {
  store.dispatch({
    type: actionTypes.CLEAR_ALGOLIA_DATA,
  });
}
