import store from "../store";
import { actionTypes } from "./saga";

/**
 * @module ComplaintsSaga
 */

/**
 *
 * @param {data} payload
 */
export function putComplaintsData(data, type) {
  store.dispatch({
    type: actionTypes.PUT_COMPLAINTS_DATA,
    payload: {
      data: data,
      type: type,
    },
  });
}

/**
 *
 * @param {data} payload
 */
export function getAllComplaintsCount(data) {
  store.dispatch({
    type: actionTypes.GET_ALL_COMPLAINTS_COUNTS,
    payload: data
  });
}

export function setComplaintsExportLoading(data) {
  store.dispatch({
    type: actionTypes.PUT_COMPLAINTS_EXPORT_LOADING,
    payload: { loading: data },
  });
}

/**
 *
 * @param {compliantData} payload
 */
export function verifyCompliant(compliantData, type) {
  store.dispatch({
    type: actionTypes.VERIFY_COMPLAINT,
    payload: {
      compliantData: compliantData,
      type: type,
    },
  });
}

// /**
//  *
//  * @param {data} payload
//  */
// export function getComplaintsAssets(locationId, selectedComplaint) {
//   store.dispatch({
//     type: actionTypes.GET_COMPLAINTS_ASSETS,
//     payload: {
//       locationId: locationId,
//       selectedComplaint: selectedComplaint,
//     },
//   });
// }

/**
 *
 *
 */
export function getTotalComplaints(data) {
  store.dispatch({
    type: actionTypes.GET_TOTAL_VALUES,
    payload: {
      data: data,
    },
  });
}

/**
 *
 * @param {data} payload complains data
 */
export function fixComplaints(complaintId, locationId, employeeId, proof) {
  store.dispatch({
    type: actionTypes.FIX_COMPLAINTS,
    payload: {
      complaintId: complaintId,
      employeeId: employeeId,
      locationId: locationId,
      proof: proof,
    },
  });
}

export function complaintTakeover(complaintId, data) {
  store.dispatch({
    type: actionTypes.COMPLAINT_TAKEOVER,
    payload: {
      complaintId: complaintId,
      employeeData: data,
    },
  });
}
