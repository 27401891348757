const surveillanceReducer = (
  state = {
    loading: false,
    retryLoading: false,
    reference: null,
    captures: null,
    status: null,
    devices: null
  },
  action
) => {
  switch (action.type) {
    case "SET_SURVEILLANCE_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };

    case "SET_SURVEILLANCE_RETRY_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };

    case "SET_REFERENCE_PICTURE":
      return {
        ...state,
        reference: action.payload.data,
      };

    case "SET_REFERENCE_PICTURE_ASSET":
      return {
        ...state,
        reference: { ...state.reference, ...action.payload.data },
      };

    case "SET_CAPTURED_PICTURE":
      return {
        ...state,
        captures: action.payload.data,
      };

    case "SET_CAPTURED_PICTURE_ASSET":
      return {
        ...state,
        captures: { ...state.captures, ...action.payload.data },
      };

    case "SET_PICTURE_STATUS":
      return {
        ...state,
        status: { ...state.status, ...action.payload.data },
      };

    case "SET_DEVICES":
      return {
        ...state,
        devices: action.payload.data,
      };

    case "RESET":
      return {
        loading: false,
        retryLoading: false,
        reference: null,
        captures: null,
        status: null,
        devices: null
      };

    default:
      return state;
  }
};
export default surveillanceReducer;
