import React from "react";

import { logout } from "../../Redux/authentication/actions";
import { connect } from "react-redux";

function Profile(props) {
  return <>Profile Page</>;
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    profile: state.profile,
  };
};

const mapDispatchToProps = function () {
  return {
    logout: () => logout(),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
