import React, { useEffect, useState } from "react";
import Button from "../../Components/Button/Button";
import InputBox from "../../Components/InputBox/InputBox";
import Modal from "../../Components/Modal/Modal";
import { CloseIcon } from "../../Assets/assets";
import { HeaderCard, ProcessCard } from "./Cards";
import { isValidArray, isValidObject } from "../../Services/validators";
import NativeSelect from "../../Components/NativeSelect/NativeSelect";
import { checkArraysAreEqual } from "../../Utils/constants";

export default function EditProcedure(props) {
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedProcessIndex, setSelectedProcessIndex] = useState(null);
  const [procedureFormData, setProcedureFormData] = useState({
    procedureName: "",
  });
  const [processFormData, setProcessFormData] = useState({
    title: "",
    description: "",
    toleranceLevel: "",
    isProofRequired: false,
  });

  const isButtonDisabled = () => {
    if (
      selectedData?.procedureType === "employee" ||
      selectedData?.procedureType === "logistics"
    ) {
      if (
        !procedureFormData.procedureName.trim() ||
        (procedureFormData.procedureName.trim() === props?.data?.title.trim() &&
          checkArraysAreEqual(selectedData?.steps, props.data?.steps))
      ) {
        return true;
      } else {
        if (!isValidArray(selectedData?.steps)) {
          return true;
        }
        return false;
      }
    } else {
      if (!procedureFormData.procedureName.trim()) {
        return true;
      } else if (!isValidArray(selectedData?.steps)) {
        return true;
      } else if (selectedData?.steps.length === 0 && props.data?.steps === 0) {
        return false;
      }

      if (
        procedureFormData.procedureName.trim() === props.data?.title.trim() &&
        checkArraysAreEqual(selectedData?.steps, props.data?.steps)
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    if (isValidObject(props.data)) {
      setSelectedData(JSON.parse(JSON.stringify(props.data)));
    }
  }, [props.data]);

  useEffect(() => {
    if (isValidObject(selectedData)) {
      setProcedureFormData({
        procedureName: selectedData.title || "",
      });
    }
  }, [selectedData]);

  useEffect(() => {
    if (selectedProcessIndex !== null) {
      const selectedStep = props.data?.steps?.[selectedProcessIndex];
      setProcessFormData({
        title: selectedStep?.title || processFormData.title,
        description: selectedStep?.description || processFormData.description,
        isProofRequired:
          selectedStep?.isProofRequired || processFormData.isProofRequired,
        toleranceLevel:
          selectedStep?.toleranceLevel || processFormData.toleranceLevel,
      });
    }
    // eslint-disable-next-line
  }, [selectedProcessIndex, props.data]);

  const handleDisableSave = () => {
    if (
      selectedData?.procedureType === "employee" ||
      selectedData?.procedureType === "logistics"
    ) {
      if (
        processFormData.title.trim() &&
        processFormData?.description.trim() &&
        typeof processFormData.isProofRequired === "boolean"
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (
        processFormData.title.trim() &&
        processFormData?.toleranceLevel.trim()
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <div
      data-cy="edit-procedure-screen"
      className="inherit-parent-height position-relative"
    >
      <div className="inherit-parent-height padding-medium">
        <HeaderCard
          title="EDIT PROCEDURE"
          description="Edit the existing procedure"
          backArrowOnClick={props.backArrowOnClick}
          theme={props.theme}
        />
        <form
          onChange={(event) => {
            setProcedureFormData({
              ...procedureFormData,
              [event.target.name]: event.target.value,
            });
          }}
          onSubmit={(event) => {
            event.preventDefault();
            if (selectedData?.procedureType === "sensor") {
              delete selectedData.description;
              delete selectedData.isProofRequired;
              props.onProcedureEdit({
                ...selectedData,
                title: procedureFormData.procedureName,
              });
            } else {
              props.onProcedureEdit({
                ...selectedData,
                title: procedureFormData.procedureName,
              });
            }
          }}
          className={` remaining-height-for-search-sensors display-flex flex-direction-column`}
        >
          <InputBox
            name="procedureName"
            type="text"
            removeResponsive={true}
            label="PROCEDURE NAME"
            className="padding-top-large "
            value={procedureFormData?.procedureName}
            fontColor={props.theme === "dark" ? "font-color-white" : ""}
            labelFontColor={props.theme === "dark" ? "font-color-white" : ""}
          />

          <div className="remaining-height-for-procedure inherit-parent-width overflow-scroll hide-scrollbar">
            {selectedData?.steps.length > 0 ? (
              selectedData?.steps.map((data, index) => (
                <div key={index} className="padding-top-large ">
                  <ProcessCard
                    data={data}
                    data-cy={`${index}-edit`}
                    editOnclick={() => {
                      setSelectedProcessIndex(index);
                      setShowModal(true);
                    }}
                    theme={props.theme}
                  />
                </div>
              ))
            ) : (
              <div></div>
            )}
          </div>

          <div
            onClick={() => {
              setShowModal(true);
              setSelectedProcessIndex(null);
              setProcessFormData({
                title: "",
                description: "",
                isProofRequired: false,
              });
            }}
            className="display-flex padding-bottom-larger flex-align-items-center flex-justify-content-center padding-top-larger cursor-pointer"
          >
            <div>Add Process</div>
          </div>
          <div className="padding-bottom-smaller">
            <Button
              text="Update"
              data-cy="update-procedure-button"
              type="submit"
              boxShadow={false}
              disabled={isButtonDisabled()}
            />
          </div>
        </form>
      </div>

      <Modal
        show={showModal}
        canIgnore={true}
        onClose={() => {
          setShowModal(!showModal);
          setSelectedProcessIndex(null);
        }}
        borderRadius="false"
        width="inherit-parent-width"
        boxShadow="box-shadow-none"
        maxWidth="max-width-528px"
        height="height-fit-to-content"
      >
        <div
          data-cy="edit-process-modal"
          className={` ${
            props.theme === "dark" ? "background-color-dark" : ""
          } background-white padding-larger box-shadow-default font-family-gilroy-regular inherit-parent-height inherit-parent-width`}
        >
          <div className="display-flex flex-justify-content-space-between flex-align-items-center">
            <div style={{ width: "16px" }} />
            <div className="">PROCESS DETAILS</div>
            <div
              onClick={() => {
                setShowModal(false);
                setSelectedProcessIndex(null);
              }}
              style={{ maxHeight: "16px" }}
              className="cursor-pointer"
            >
              <CloseIcon
                height="16"
                width="16"
                color={props.theme === "dark" ? "white" : "#404040"}
              />
            </div>
          </div>
          <form
            onChange={(event) => {
              if (event.target.name === "isProofRequired") {
                setProcessFormData({
                  ...processFormData,
                  [event.target.name]:
                    event.target.value === "Yes" ? true : false,
                });
              } else {
                setProcessFormData({
                  ...processFormData,
                  [event.target.name]: event.target.value,
                });
              }
            }}
            onSubmit={(event) => {
              event.preventDefault();
              if (selectedProcessIndex !== null) {
                if (
                  selectedData?.procedureType === "employee" ||
                  selectedData?.procedureType === "logistics"
                ) {
                  selectedData.steps[selectedProcessIndex] = {
                    title: processFormData?.title,
                    description: processFormData?.description,
                    isProofRequired: processFormData?.isProofRequired,
                  };
                } else {
                  selectedData.steps[selectedProcessIndex] = {
                    title: processFormData?.title,
                    toleranceLevel: processFormData?.toleranceLevel,
                  };
                }
              } else {
                if (
                  selectedData?.procedureType === "employee" ||
                  selectedData?.procedureType === "logistics"
                ) {
                  setSelectedData({
                    ...selectedData,
                    steps: [
                      ...selectedData.steps,
                      {
                        title: processFormData?.title,
                        description: processFormData?.description,
                        isProofRequired: processFormData?.isProofRequired,
                      },
                    ],
                  });
                } else {
                  setSelectedData({
                    ...selectedData,
                    steps: [
                      ...selectedData.steps,
                      {
                        title: processFormData?.title,
                        toleranceLevel: processFormData?.toleranceLevel,
                      },
                    ],
                  });
                }
              }
              setShowModal(false);
            }}
            className="display-flex flex-direction-column flex-justify-content-space-between"
          >
            <div>
              <InputBox
                name="title"
                type="text"
                removeResponsive={true}
                label="PROCESS NAME"
                className="padding-top-large "
                value={processFormData?.title}
                fontColor={props.theme === "dark" ? "font-color-white" : ""}
                labelFontColor={
                  props.theme === "dark" ? "font-color-white" : ""
                }
              />
              {(selectedData?.procedureType === "employee" ||
                selectedData?.procedureType === "logistics") && (
                <>
                  <InputBox
                    name="description"
                    type="text"
                    removeResponsive={true}
                    label="DESCRIPTION"
                    className="padding-top-large"
                    value={processFormData.description}
                    fontColor={props.theme === "dark" ? "font-color-white" : ""}
                    labelFontColor={
                      props.theme === "dark" ? "font-color-white" : ""
                    }
                  />
                  <NativeSelect
                    name="isProofRequired"
                    removeResponsive={true}
                    label="IMAGE REQUIRED"
                    selectClassName="text-transform-capitalize margin-top-large"
                    options={["", "Yes", "No"]}
                    className="margin-top-large"
                    defaultValue={
                      processFormData.isProofRequired ? "Yes" : "No"
                    }
                    fontColor={
                      props.theme === "dark"
                        ? "font-color-white"
                        : "font-color-secondary"
                    }
                  />
                </>
              )}

              {selectedData?.procedureType === "sensor" && (
                <InputBox
                  name="toleranceLevel"
                  type="text"
                  removeResponsive={true}
                  label="Tolerance Level"
                  className="padding-top-large"
                  value={processFormData?.toleranceLevel}
                  fontColor={props.theme === "dark" ? "font-color-white" : ""}
                  labelFontColor={
                    props.theme === "dark" ? "font-color-white" : ""
                  }
                />
              )}
            </div>

            <div className="padding-top-large display-flex">
              <Button
                text="Save"
                data-cy="save-process-button"
                type="submit"
                disabled={handleDisableSave()}
                boxShadow={false}
              />
              {selectedProcessIndex !== null &&
                selectedData?.steps?.[selectedProcessIndex]?.title &&
                selectedData?.steps?.[selectedProcessIndex]?.description &&
                typeof selectedData?.steps?.[selectedProcessIndex]
                  ?.isProofRequired === "boolean" && (
                  <Button
                    text="Delete"
                    variant="transparent"
                    className="font-color-red margin-left-larger border-1px-e5e5e5"
                    type="button"
                    onClick={() => {
                      setSelectedData({
                        ...selectedData,
                        steps: selectedData.steps.filter(
                          (_data, index) => index !== selectedProcessIndex
                        ),
                      });
                      setSelectedProcessIndex(null);
                      setShowModal(false);
                    }}
                    boxShadow={false}
                  />
                )}
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}
