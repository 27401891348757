import React, { useState, useEffect } from "react";
import "./Modal.css";

import bottomLeftAdlibe from "../../Assets/modalBackground/bottomLeftAdlibe.svg";
import topRightAdlibe from "../../Assets/modalBackground/topRightAdlibe.svg";
import LHS_adlibe from "../../Assets/modalBackground/LHS_adlibe.svg";
import RHS_adlibe from "../../Assets/modalBackground/RHS_adlibe.svg";
import modalFrameTopLeft from "../../Assets/modalBackground/modalFrameTopLeft.svg";

function Modal(props) {
  const [show, setShow] = useState();

  useEffect(() => {
    setShow(props.show ? props.show : false);
  }, [props.show]);

  const getBackgroundClassNames = (customClassNames) => {
    return `z-index-101 background-color-black-with-opacity-light position-fixed top-0 bottom-0 right-0 left-0 inherit-parent-height inherit-parent-width hide-scroll-bar${
      customClassNames ? customClassNames : ``
    }`;
  };

  return (
    <>
      {show === true ? (
        <>
          <div
            data-cy="modalBackground"
            className={
              props.RemoveBlackOverlay
                ? ""
                : getBackgroundClassNames(props.modalBackgroundClassName)
            }
            onClick={() => {
              if (props.canIgnore === true) {
                setShow(false);
                if (typeof props.onClose === "function") {
                  props.onClose();
                }
              }
            }}
          >
            <img
              src={topRightAdlibe}
              alt="left"
              className=" position-absolute"
              style={{
                top: "140px",
                right: "170px",
              }}
            />

            <img
              src={bottomLeftAdlibe}
              alt="bottom left"
              className=" position-absolute"
              style={{
                bottom: "140px",
                left: "170px",
              }}
            />

            <img
              src={RHS_adlibe}
              alt="RHS"
              className=" position-absolute"
              style={{
                top: "22%",
                right: "50px",
              }}
            />

            <img
              src={LHS_adlibe}
              alt="LHS"
              className=" position-absolute"
              style={{
                top: "22%",
                left: "50px",
              }}
            />
          </div>
          <div
            className={`z-index-101 ${
              props.modalBodyClassName ? props.modalBodyClassName : ``
            } ${
              props.borderRadius ? props.borderRadius : "border-radius-default"
            }
            ${props.position ? props.position : "position-fixed-center-self"} ${
              props.height ? props.height : "minimum-height-250px"
            }
            ${props.boxShadow ? props.boxShadow : "box-shadow-default"} ${
              props.background ? props.background : "background-white"
            }
            ${props.minWidth ? props.minWidth : "minimum-width-600px"} ${
              props.width ? props.width : "width-auto"
            }
            ${props.maxWidth ? props.maxWidth : "max-width"} ${
              props.maxHeight ? props.maxHeight : "max-height"
            } background-transparent`}
          >
            {props.showFrame && (
              <>
                <img
                  src={modalFrameTopLeft}
                  alt="modalFrameTopLeft"
                  className=" position-absolute"
                  style={{
                    top: "-25px",
                    left: "-25px",
                  }}
                />
              </>
            )}

            {props.children ? props.children : <div></div>}
          </div>
        </>
      ) : null}
    </>
  );
}

export default Modal;
