const algoliaReducer = (
  state = {
    data: { query: "", from: null },
  },
  action
) => {
  switch (action.type) {
    case "SET_ALGOLIA_QUERY":
      return {
        ...state,
        data: {
          ...state.data,
          query: action.payload.data.query,
          from: action.payload.data.from,
        },
      };

    case "RESET_ALGOLIA_DATA":
      return {
        data: { query: "", from: null },
      };

    case "RESET":
      return {
        data: { query: "", from: null },
      };

    default:
      return state;
  }
};
export default algoliaReducer;
