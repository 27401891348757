import store from "../store";
import { actionTypes } from "./saga";

export function putSensorsData(data) {
  store.dispatch({
    type: actionTypes.PUT_SENSORS_DATA,
    payload: { data: data },
  });
}

export function createSensor(data) {
  store.dispatch({
    type: actionTypes.CREATE_SENSOR,
    payload: { data: data },
  });
}

export function assignProcedureToSensor(sensorId, data) {
  store.dispatch({
    type: actionTypes.ASSIGN_PROCEDURE_TO_SENSOR,
    payload: { data: data, sensorId: sensorId },
  });
}

export function deleteProcedureLinkedToSensor(sensorId, data) {
  store.dispatch({
    type: actionTypes.DELETE_LINKED_SENSOR_PROCEDURE,
    payload: { data: data, sensorId: sensorId },
  });
}
