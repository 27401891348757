import "./NoSelectionFallback.css";
import {
  LogisticIcon,
  NoSelectionFallbackImage,
  SensorIcon,
  UserIcon,
} from "../../Assets/assets";

export default function NoSelectionFallBack(props) {
  return (
    <div
      className="inherit-parent-height display-flex flex-direction-column flex-align-items-center flex-justify-content-center"
      data-cy={props.dataCy || "no-selection-fallback"}
    >
      <NoSelectionFallbackImage />
      <div className=" padding-top-large font-size-large">
        {props.title}
      </div>
      <div className="font-size-default text-align-center padding-medium">
        {props.description}
      </div>
      {props.create && (
        <div className="display-flex flex-align-items-center padding-top-large">
          <div
            data-cy="create-employee-preset"
            onClick={props.userOnClick}
            className="display-flex flex-align-items-center border-bottom-primary cursor-pointer"
          >
            <div style={{ height: "22px" }} className="padding-right-medium">
              <UserIcon color="white" height={18} />
            </div>
            <div className="font-size-medium   ">
              Employees
            </div>
          </div>
          <div className="font-size-small padding-left-large padding-right-large">
            OR
          </div>
          <div
            data-cy="create-sensor-preset"
            onClick={props.sensorOnClick}
            className="display-flex flex-align-items-center border-bottom-primary cursor-pointer"
          >
            <div style={{ height: "22px" }} className="padding-right-medium">
              <SensorIcon color="white" height={22} />
            </div>
            <div className="font-size-medium   ">
              Sensors
            </div>
          </div>

          <div className="font-size-small padding-left-large padding-right-large">
            OR
          </div>
          <div
            data-cy="create-logistic-preset"
            onClick={props.logisticOnClick}
            className="display-flex flex-align-items-center border-bottom-primary cursor-pointer"
          >
            <div style={{ height: "22px" }} className="padding-right-medium">
              <LogisticIcon color="white" height={22} />
            </div>
            <div className="font-size-medium   ">
              Logistics
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
