import React from "react";
import BarChart from "../../Components/Graphs/BarChart";
import {
  stripeBarChartPlugin,
  stripeDoughnutChartPlugin,
} from "../Test/stripePlugin";
import { DoughnutChart } from "../../Components/Graphs/DoughnutChart";
import { CornerLIcon } from "../../Assets/assets";

export default function Summary() {
  return (
    <div>
      <LocationSummary />
    </div>
  );
}

const LocationSummary = (second) => {
  const barChartData = {
    labels: ["Jan", "Feb", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Dataset 1",
        backgroundColor: "white",
        borderColor: "black",
        borderWidth: 0,
        data: [65, 59, 80, 81, 56, 55, 40],
        barThickness: 16,
      },
    ],
  };

  const barChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        callbacks: {
          label: function(context) {
            const label = context.label || "";
            const value = context.raw || "";
            return `${label}: ${value}`;
          },
        },
        position: "average",
        external: function(context) {
          const tooltip = context.tooltip;
          if (tooltip.opacity === 0) {
            return;
          }

          // Custom tooltip element
          let tooltipEl = document.getElementById("chartjs-tooltip");
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.backgroundColor = "rgba(0,0,0,0.7)";
            tooltipEl.style.color = "#fff";
            tooltipEl.style.padding = "5px";
            tooltipEl.style.borderRadius = "3px";
            tooltipEl.style.pointerEvents = "none";
            document.body.appendChild(tooltipEl);
          }

          tooltipEl.innerHTML = tooltip.body
            .map((bodyItem) => bodyItem.lines)
            .join("<br>");

          const chartPosition = context.chart.canvas.getBoundingClientRect();
          tooltipEl.style.left = `${chartPosition.left +
            window.pageXOffset +
            (tooltip.caretX - 10)}px`;
          tooltipEl.style.top = `${chartPosition.top +
            window.pageYOffset -
            tooltip.height -
            10}px`;
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: true,
          borderColor: "white",
          borderWidth: 2,
        },
        ticks: {
          display: true,
          color: "white",
        },
      },
      y: {
        grid: {
          display: false,
          drawBorder: true,
          borderColor: "white",
          borderWidth: 2,
        },
        ticks: {
          display: true,
          color: "white",
        },
      },
    },
  };

  const doughnutChartData = {
    labels: ["White", "Red", "Blue"],
    datasets: [
      {
        label: "# of Votes",
        data: [35, 30, 35],
        backgroundColor: ["#FFFFFF", "#3676D6", "#FF6C6C"],
        borderColor: ["#FFFFFF", "#3676D6", "#FF6C6C"],
        borderWidth: 1,
      },
    ],
  };

  const doughnutChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        callbacks: {
          label: function(context) {
            const label = context.label || "";
            const value = context.raw || "";
            return `${label}: ${value}`;
          },
        },
        position: "average",
        external: function(context) {
          const tooltip = context.tooltip;
          if (tooltip.opacity === 0) {
            return;
          }

          // Custom tooltip element
          let tooltipEl = document.getElementById("chartjs-tooltip");
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.backgroundColor = "rgba(0,0,0,0.7)";
            tooltipEl.style.color = "#fff";
            tooltipEl.style.padding = "5px";
            tooltipEl.style.borderRadius = "3px";
            tooltipEl.style.pointerEvents = "none";
            document.body.appendChild(tooltipEl);
          }

          // Hide tooltip when not active
          if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          tooltipEl.innerHTML = tooltip.body
            .map((bodyItem) => bodyItem.lines)
            .join("<br>");

          const chartPosition = context.chart.canvas.getBoundingClientRect();
          tooltipEl.style.left = `${chartPosition.left +
            window.pageXOffset +
            (tooltip.caretX - 10)}px`;
          tooltipEl.style.top = `${chartPosition.top +
            window.pageYOffset -
            tooltip.height -
            10}px`;
          tooltipEl.style.opacity = 1;
        },
      },
    },
    cutout: "50%",
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div className="display-flex flex-justify-content-center remaining-body-height-from-header padding-top-larger">
      <div
        style={{
          minWidth: "80%",
        }}
        className="width-80-percentage inherit-parent-height"
      >
        <div className="display-flex flex-justify-content-space-between">
          <div
            style={{
              width: "33%",
            }}
            onMouseLeave={() => {
              let tooltipEl = document.getElementById("chartjs-tooltip");
              if (tooltipEl) {
                tooltipEl.style.opacity = 0;
              }
            }}
          >
            <div className="font-size-medium padding-bottom-large text-align-center">
              GRAPH DATA
            </div>
            <div>
              <BarChart
                data={barChartData}
                options={barChartOptions}
                plugins={[stripeBarChartPlugin]}
              />
            </div>
          </div>
          <div
            style={{
              width: "33%",
            }}
            className="display-flex overflow-scroll"
          >
            <div className="inherit-parent-width display-flex flex-direction-column flex-align-items-center flex-justify-content-space-between">
              <div className="font-size-medium padding-bottom-small text-align-center">
                GRAPH DATA
              </div>
              <div className="padding-bottom-large">
                <ProgressChart title="POSITIVE PROCEDURES" />
              </div>

              <div className="padding-bottom-large">
                <ProgressChart title="POSITIVE PROCEDURES" />
              </div>
            </div>
          </div>
          <div
            className="inherit-parent-height"
            style={{
              width: "33%",
            }}
          >
            <div className="font-size-medium text-align-center">GRAPH DATA</div>
            <div
              style={{
                padding: "24px",
              }}
            ></div>
            <div
              onMouseLeave={() => {
                let tooltipEl = document.getElementById("chartjs-tooltip");
                if (tooltipEl) {
                  tooltipEl.style.opacity = 0;
                }
              }}
              className="display-flex inherit-parent-height padding-top-larger margin-top-larger"
            >
              <div className="inherit-parent-height">
                <DoughnutChart
                  data={doughnutChartData}
                  options={doughnutChartOptions}
                  plugins={[stripeDoughnutChartPlugin]}
                />
                <div className="font-size-medium text-align-center padding-top-large">
                  POSITIVE
                </div>
              </div>
              <div style={{ padding: "8px" }} />
              <div className="inherit-parent-height">
                <DoughnutChart
                  data={doughnutChartData}
                  options={doughnutChartOptions}
                  plugins={[stripeDoughnutChartPlugin]}
                />
                <div className="font-size-medium text-align-center padding-top-large">
                  POSITIVE
                </div>
              </div>
              <div style={{ padding: "8px" }} />
              <div className="inherit-parent-height">
                <DoughnutChart
                  data={doughnutChartData}
                  options={doughnutChartOptions}
                  plugins={[stripeDoughnutChartPlugin]}
                />
                <div className="font-size-medium text-align-center padding-top-large">
                  POSITIVE
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

const ProgressChart = (props) => {
  return (
    <div>
      <div className="padding-bottom-large font-size-medium">{props.title}</div>
      <div className=" display-flex">
        <div className="padding-bottom-large margin-right-large">
          <CornerLIcon width={8} height={8} />
        </div>
        {Array.from({ length: 54 }, (_, index) => (
          <div
            key={index}
            style={{
              height: "16px",
              transform: "rotate(45deg)",
              padding: "1px",
            }}
            className={`margin-right-small margin-top-large margin-bottom-large ${
              index <= (40 / 100) * 54
                ? "background-color-light-blue"
                : "background-white"
            }`}
          />
        ))}
        <div
          className="margin-left-large"
          style={{
            transform: "rotate(180deg)",
          }}
        >
          <CornerLIcon width={8} height={8} />
        </div>
      </div>
    </div>
  );
};
