import React, { useEffect, useMemo, useState } from "react";
import { isValidArray } from "../../Services/validators";
import { isDigit } from "../../Utils/constants";
import ControlButton from "../ControlButton/ControlButton";
import TrackProgress from "../TrackProgress/TrackProgress";
import "./ProceduresPreview.css";
import { CornerLIcon } from "../../Assets/assets";
import CustomScrollBar from "../CustomScrollBar/CustomScrollBar";

export default function ProceduresPreview(props) {
  const [activeOption, setActiveOption] = useState("location");
  const [employeeIntervals, setEmployeeIntervals] = useState(null);

  const category = ["location", "procedure", "user"];

  useEffect(() => {
    if (
      props.employeeData &&
      isValidArray(props.employeeData?.linkedProcedures)
    ) {
      const intervals = props.employeeData?.linkedProcedures?.filter(
        (procedure) =>
          procedure?.procedureId === props.proceduresHistory?.procedureId
      );

      setEmployeeIntervals(intervals);
    }
    // eslint-disable-next-line
  }, [props.employeeData]);

  const totals = useMemo(() => {
    return props.proceduresHistory?.latestUpdate?.reduce(
      (acc, { kpi, penalty }) => {
        if (kpi) {
          acc.initialKpi += kpi.initial ?? 0;
          acc.finalKpi += kpi.final ?? 0;
        }

        if (penalty) {
          acc.penalty += penalty.cost ?? 0;
        }

        return acc;
      },
      { initialKpi: 0, finalKpi: 0, penalty: 0 }
    );
  }, [props.proceduresHistory?.latestUpdate]);

  return (
    <div
      className={` modal-frame background-color-dark inherit-parent-height inherit-parent-width display-flex padding-larger min-width-170px `}
    >
      {/* Header-section */}
      <div
        className=" inherit-parent-height padding-right-medium display-flex flex-direction-column"
        data-cy="header-section"
        style={{
          width: "232px",
        }}
      >
        {category.map((option, index) => (
          <ControlButton
            key={index}
            text={option}
            selected={activeOption === option}
            onClick={() => {
              setActiveOption(option);
            }}
            theme={props.theme}
            dataCy={
              activeOption === option
                ? `header-selected-${option}`
                : `header-select-${option}`
            }
          />
        ))}
      </div>
      <div
        style={{
          marginLeft: "48px",
        }}
        className="parent-width-sub-156px inherit-parent-height display-flex flex-direction-column padding-left-medium position-relative"
        data-cy="procedure-body-section"
      >
        <CustomScrollBar horizontal="left">
          <div className="inherit-parent-width z-index-1 padding-top-default padding-bottom-default margin-bottom-medium text-align-left display-flex flex-justify-content-space-between position-sticky top-0 background-color-dark">
            <div>
              <div
                className=" font-size-larger text-uppercase padding-bottom-default"
                data-cy={props.procedures?.title || "N/A"}
              >
                {props.procedures?.title || "N/A"}
              </div>
              <div
                className=" font-size-default text-uppercase padding-bottom-default"
                data-cy={props.locationData?.location || "N/A"}
              >
                {props.locationData?.location || "N/A"}
              </div>
            </div>

            {activeOption === "procedure" &&
              props.procedures?.title === "Key performance indicators" && (
                <div className="display-flex">
                  <div
                    style={{
                      transform: "translate(0px,0px)",
                    }}
                  >
                    <CornerLIcon width={8} height={8} />
                  </div>{" "}
                  <div className="padding-large">
                    <Fields
                      title="KPI"
                      width={"75px"}
                      data={totals.initialKpi}
                    />{" "}
                    <div className="padding-small" />
                    <Fields
                      title="PENALTY"
                      width={"75px"}
                      data={totals.penalty}
                    />{" "}
                  </div>
                  <div
                    style={{
                      transform: "rotate(180deg) translate(0px,0px)",
                    }}
                  >
                    <CornerLIcon width={8} height={8} />
                  </div>
                </div>
              )}
          </div>

          {/* Location section */}
          {activeOption === "location" && (
            <div
              style={{
                height: "calc(100% - 90px)",
              }}
              className=" inherit-parent-height inherit-parent-width display-flex flex-direction-column font-size-default"
              data-cy="location-description-section"
            >
              {[
                {
                  title: "location",
                  value: props.locationData?.location || "N/A",
                },
                { title: "zone", value: props.locationData?.zone || "N/A" },
                { title: "ward", value: props.locationData?.ward || "N/A" },
                {
                  title: "pct number",
                  value: props.locationData?.PCTNo || "N/A",
                },
              ].map((data) => (
                <div className=" display-flex inherit-parent-width padding-vertical-default">
                  <div
                    className=" display-flex flex-justify-content-space-between"
                    style={{ width: "180px" }}
                  >
                    <p
                      className=" text-uppercase"
                      style={{
                        width: "162px",
                      }}
                      data-cy={`${data?.title}`}
                    >
                      {data?.title}
                    </p>
                    <p className=" padding-horizontal-medium ">:</p>
                  </div>
                  <div data-cy={`${data?.value}`}> {data?.value}</div>
                </div>
              ))}
            </div>
          )}

          {/* procedure section */}
          {activeOption === "procedure" && (
            <div
              className="inherit-parent-width"
              style={{
                height: "calc(100% - 90px)",
              }}
              data-cy="procedure-description-section"
            >
              {props.proceduresHistory &&
                isValidArray(props.proceduresHistory?.latestUpdate) && (
                  <TrackProgress
                    data={props.proceduresHistory?.latestUpdate.map((data) => {
                      return {
                        title: data?.title,
                        description: data?.remarks,
                        assets: data?.fileSrc,
                        success: data?.success,
                        remarks: data?.remarks,
                        requiredRemarks: data?.requiredRemarks,
                        kpi: data?.kpi,
                        penalty: data?.penalty,
                        updatedAt: data?.updatedAt,
                      };
                    })}
                    procedures={props.procedures}
                    theme={props.theme}
                    setPreview={props.setPreviewImage}
                    dataCy="procedure-view"
                  />
                )}
            </div>
          )}

          {/* User details section */}
          {activeOption === "user" && (
            <div
              style={{
                height: "calc(100% - 90px)",
              }}
              className=" inherit-parent-height inherit-parent-width display-flex flex-direction-column font-size-default"
              data-cy="user-description-section"
            >
              {[
                { title: "name", value: props.employeeData?.name || "N/A" },
                {
                  title: "contact",
                  value: isDigit(
                    props.employeeData?.phoneNumber?.split("+91")?.[1]
                  )
                    ? props.employeeData?.phoneNumber
                    : "N/A",
                },
                {
                  title: "interval",
                  value: `${
                    isDigit(employeeIntervals?.[0]?.interval)
                      ? Number(employeeIntervals?.[0]?.interval)
                      : "N/A"
                  } HOUR${
                    Number(employeeIntervals?.[0]?.interval) > 1 ? "S" : ""
                  }`,
                },
              ].map((data) => (
                <div className=" display-flex inherit-parent-width padding-vertical-default">
                  <div
                    className=" text-uppercase "
                    style={{ width: "180px" }}
                    data-cy={data?.title}
                  >
                    {" "}
                    {data?.title}
                  </div>
                  <p className=" padding-horizontal-medium">:</p>
                  <div data-cy={data?.value}> {data?.value}</div>
                </div>
              ))}
            </div>
          )}
        </CustomScrollBar>
      </div>
    </div>
  );
}

const Fields = (props) => {
  return (
    <div className="display-flex">
      <div className="display-flex">
        <div
          className="white-space-no-wrap font-size-medium"
          style={{ width: props.width }}
          data-cy={`${props.title}`}
        >
          {props.title}
        </div>
        <div className="padding-horizontal-medium">:</div>
      </div>
      <div
        className="text-align-left inherit-parent-width text-overflow-ellipsis font-size-medium text-uppercase"
        data-cy={props.dataCy || props.data}
        style={{
          minWidth: "fit-content",
          maxWidth: "380px",
        }}
      >
        {props.data}
      </div>
    </div>
  );
};
