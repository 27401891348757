import store from "../store";
import { actionTypes } from "./saga";

/**
 * @module TracksSaga
 */

/**
 *
 * @param {phoneNumber, timestamp} payload
 */
export function getTracksData(phoneNumber, timestamp) {
  store.dispatch({
    type: actionTypes.GET_TRACKS_DATA,
    payload: {
      phoneNumber: phoneNumber,
      timestamp: timestamp,
    },
  });
}

export function clearTracksData() {
  store.dispatch({
    type: actionTypes.CLEAR_TRACKS_DATA,
  });
}
