import React, { useEffect, useState } from "react";
import { CloseIcon } from "../../Assets/assets";
import InputBox from "../../Components/InputBox/InputBox";
import Button from "../../Components/Button/Button";
import "./Procedures.css";
import Modal from "../../Components/Modal/Modal";
import NativeSelect from "../../Components/NativeSelect/NativeSelect";
import { HeaderCard, ProcessCard } from "./Cards";
import { isValidArray, isValidObject } from "../../Services/validators";

export default function CreateProcedure(props) {
  const [showModal, setShowModal] = useState(false);
  const [processData, setProcessData] = useState([]);
  const [selectedProcessIndex, setSelectedProcessIndex] = useState(null);
  const [procedureFormData, setProcedureFormData] = useState({
    procedureName: "",
    toleranceLevel: "",
  });
  const [processFormData, setProcessFormData] = useState({
    title: "",
    description: "",
    isProofRequired: false,
  });
  const [IsValidToleranceLevel, setIsValidToleranceLevel] = useState(true);
  const [presetData, setPresetData] = useState(null);

  useEffect(() => {
    if (isValidObject(props.procedurePresets)) {
      setPresetData(
        Object.values(props.procedurePresets).map((data) =>
          data?.title?.toString()?.toLowerCase()
        )
      );
    }
  }, [props.procedurePresets]);

  const handleDisableButton = () => {
    return procedureFormData.procedureName.trim() &&
      isValidArray(presetData) &&
      !presetData?.includes(procedureFormData.procedureName) &&
      processData.length > 0
      ? false
      : true;
  };
  const handleDisableSaveButton = () => {
    if (
      props.procedureType === "employee" ||
      props.procedureType === "logistics"
    ) {
      return processFormData.title.trim() &&
        processFormData.description.trim() &&
        typeof processFormData.isProofRequired === "boolean"
        ? false
        : true;
    } else {
      return processFormData.title.trim() &&
        procedureFormData.toleranceLevel.trim() &&
        IsValidToleranceLevel
        ? false
        : true;
    }
  };
  useEffect(() => {
    if (selectedProcessIndex !== null) {
      setProcessFormData({
        title: processData[selectedProcessIndex]?.title || "",
        description: processData[selectedProcessIndex]?.description || "",
        isProofRequired: processData[selectedProcessIndex]?.isProofRequired,
      });
    }
    // eslint-disable-next-line
  }, [selectedProcessIndex]);

  return (
    <div
      data-cy="create-procedure-screen"
      className="inherit-parent-height position-relative"
    >
      <div className="inherit-parent-height padding-medium">
        <HeaderCard
          title="CREATE PROCEDURE"
          description={`Create a new procedure for ${props.procedureType}`}
          backArrowOnClick={props.backArrowOnClick}
          theme={props.theme}
        />
        <form
          onChange={(event) => {
            setProcedureFormData({
              ...procedureFormData,
              [event.target.name]: event.target.value,
            });
          }}
          onSubmit={(event) => {
            event.preventDefault();
            if (props.procedureType === "sensor") {
              props.onProcedureCreate({
                procedureType: props.procedureType,
                steps: [
                  {
                    title: processFormData.title,
                    toleranceLevel: procedureFormData.toleranceLevel,
                  },
                ],
                title: procedureFormData.procedureName,
              });
            } else {
              props.onProcedureCreate({
                procedureType: props.procedureType,
                steps: processData,
                title: procedureFormData.procedureName,
              });
            }

            setProcedureFormData({
              procedureName: "",
              toleranceLevel: "",
            });
            setProcessFormData({
              title: "",
              description: "",
              isProofRequired: false,
            });
            setProcessData([]);
          }}
          className={`display-flex flex-direction-column remaining-height-for-search-sensors`}
        >
          <InputBox
            name="procedureName"
            type="text"
            data-cy="create-procedure-name-field"
            removeResponsive={true}
            label="PROCEDURE NAME"
            className="padding-top-large "
            value={procedureFormData.procedureName}
            fontColor={props.theme === "dark" ? "font-color-white" : ""}
            labelFontColor={props.theme === "dark" ? "font-color-white" : ""}
            validation={() => {
              const value = procedureFormData?.procedureName?.toLowerCase();
              if (typeof value === "string" && value?.trim()?.length > 0) {
                if (isValidArray(presetData) && presetData?.includes(value)) {
                  return {
                    status: false,
                    message: "Procedure already exists",
                  };
                }
              }
            }}
          />
          <div className="remaining-height-for-procedure overflow-scroll hide-scrollbar">
            {processData.length > 0 &&
              processData.map((data, index) => (
                <div className="padding-top-large">
                  <ProcessCard
                    data={data}
                    editOnclick={() => {
                      setSelectedProcessIndex(index);
                      setShowModal(true);
                    }}
                    theme={props.theme}
                  />
                </div>
              ))}
          </div>

          <div
            data-cy="add-process"
            onClick={() => {
              setShowModal(true);
              setSelectedProcessIndex(null);
              setProcessFormData({
                title: "",
                description: "",
                isProofRequired: false,
              });
            }}
            className="display-flex padding-bottom-larger flex-align-items-center flex-justify-content-center padding-top-larger"
          >
            <div className=" padding-left-default">Add Process</div>
          </div>
          <Button
            text="Create"
            data-cy="create-procedure-button"
            type="submit"
            disabled={handleDisableButton()}
            boxShadow={false}
          />
        </form>
      </div>

      <Modal
        show={showModal}
        canIgnore={true}
        onClose={() => {
          setShowModal(!showModal);
        }}
        borderRadius="false"
        width="inherit-parent-width"
        boxShadow="box-shadow-none"
        maxWidth="max-width-528px"
        height="height-fit-to-content"
      >
        <div
          data-cy="add-process-modal"
          className={`${
            props.theme === "dark" ? "background-color-dark" : ""
          } padding-larger box-shadow-default font-family-gilroy-regular inherit-parent-height inherit-parent-width`}
        >
          <div className="display-flex flex-justify-content-space-between flex-align-items-center">
            <div style={{ width: "16px" }} />
            <div className="">PROCESS DETAILS</div>
            <div
              onClick={() => {
                setShowModal(false);
              }}
              style={{ maxHeight: "16px" }}
              className="cursor-pointer"
            >
              <CloseIcon
                height="16"
                width="16"
                color={props.theme === "dark" ? "white" : "#404040"}
              />
            </div>
          </div>
          <form
            onChange={(event) => {
              if (event.target.name === "toleranceLevel") {
                setProcedureFormData({
                  ...procedureFormData,
                  [event.target.name]: event.target.value,
                });
              } else if (event.target.name === "isProofRequired") {
                setProcessFormData({
                  ...processFormData,
                  [event.target.name]:
                    event.target.value === "Yes" ? true : false,
                });
              } else {
                setProcessFormData({
                  ...processFormData,
                  [event.target.name]: event.target.value,
                });
              }
            }}
            onSubmit={(event) => {
              event.preventDefault();
              if (selectedProcessIndex !== null) {
                processData[selectedProcessIndex] = processFormData;
              } else {
                setProcessData([...processData, processFormData]);
              }
              setProcessFormData({
                title: "",
                description: "",
                isProofRequired: false,
              });
              setShowModal(false);
            }}
            className="display-flex flex-direction-column flex-justify-content-space-between"
          >
            <div>
              <InputBox
                name="title"
                type="text"
                data-cy="process-name-field"
                removeResponsive={true}
                label="PROCESS NAME"
                className="padding-top-large "
                value={processFormData.title}
                fontColor={props.theme === "dark" ? "font-color-white" : ""}
                labelFontColor={
                  props.theme === "dark" ? "font-color-white" : ""
                }
              />
              {(props.procedureType === "employee" ||
                props.procedureType === "logistics") && (
                <>
                  <InputBox
                    name="description"
                    type="text"
                    data-cy="process-name-description"
                    removeResponsive={true}
                    label="DESCRIPTION"
                    className="padding-top-large "
                    value={processFormData.description}
                    fontColor={props.theme === "dark" ? "font-color-white" : ""}
                    labelFontColor={
                      props.theme === "dark" ? "font-color-white" : ""
                    }
                  />

                  <NativeSelect
                    name="isProofRequired"
                    removeResponsive={true}
                    label="PROOF REQUIRED"
                    selectClassName="text-transform-capitalize margin-top-large"
                    options={["", "Yes", "No"]}
                    className="margin-top-large"
                    defaultValue={
                      processFormData.isProofRequired ? "Yes" : "No"
                    }
                    fontColor={
                      props.theme === "dark"
                        ? "font-color-white"
                        : "font-color-secondary"
                    }
                    // value={processFormData.isProofRequired}
                  />
                </>
              )}

              {props.procedureType === "sensor" && (
                <InputBox
                  name="toleranceLevel"
                  data-cy="create-procedure-toleranceLevel-field"
                  type="text"
                  removeResponsive={true}
                  label="TOLERANCE LEVEL"
                  className="padding-top-large "
                  value={procedureFormData.toleranceLevel}
                  fontColor={props.theme === "dark" ? "font-color-white" : ""}
                  labelFontColor={
                    props.theme === "dark" ? "font-color-white" : ""
                  }
                  validation={(value) => {
                    if (!value) {
                      setIsValidToleranceLevel(false);
                      return {
                        status: true,
                      };
                    }
                    if (!(value <= 100)) {
                      setIsValidToleranceLevel(false);
                      return {
                        status: false,
                        message: "Please enter a valid tolerance level",
                      };
                    } else {
                      setIsValidToleranceLevel(true);
                      return {
                        status: true,
                      };
                    }
                  }}
                />
              )}
            </div>
            <div className="padding-top-large display-flex">
              <Button
                text="Save"
                type="submit"
                data-cy={"save-process-button"}
                disabled={handleDisableSaveButton()}
                boxShadow={false}
              />

              {selectedProcessIndex !== null &&
                processData[selectedProcessIndex]?.title &&
                processData[selectedProcessIndex]?.description &&
                typeof processData[selectedProcessIndex]?.isProofRequired ===
                  "boolean" && (
                  <Button
                    text="Delete"
                    variant="transparent"
                    className="font-color-red margin-left-larger border-1px-e5e5e5"
                    type="button"
                    onClick={() => {
                      setProcessData(
                        processData.filter(
                          (_data, index) => index !== selectedProcessIndex
                        )
                      );
                      setShowModal(false);
                    }}
                    boxShadow={false}
                  />
                )}
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}
