import React, { useRef, useState, useEffect } from "react";
import "./AudioPlayer.css";
import { isValidObject } from "../../Services/validators";
import { PauseIcon, PlayIcon } from "../../Assets/assets";

function AudioPlayer(props) {
  const [play, setPlay] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [loading, setLoading] = useState(false);
  const audio = useRef(null);
  const isGetAudioAndPlay = useRef(false);

  const audioPlayHandler = () => {
    if (
      audio.current &&
      typeof props.src === "string" &&
      props.src.length > 0
    ) {
      if (play === false) {
        audio.current.play();
        setPlay(true);
      } else if (play === true && currentTime > 0) {
        audio.current.pause();
        setPlay(false);
      }
    } else if (typeof props.getDownloadURL === "function") {
      setLoading(true);
      isGetAudioAndPlay.current = true;
      props.getDownloadURL();
    }
  };

  //to audio play method
  useEffect(() => {
    if (
      isGetAudioAndPlay.current === true &&
      audio.current &&
      typeof props.src === "string" &&
      props.src.length > 0
    ) {
      if (play === false) {
        audio.current.play();
        setPlay(true);
      } else {
        audio.current.pause();
        setPlay(false);
      }
      audio.current.onended = () => {
        setPlay(false);
      };
    }
    audio.current.onended = () => {
      setPlay(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.src, audio.current]);

  //audio change listerner
  useEffect(() => {
    if (
      audio.current &&
      typeof props.src === "string" &&
      props.src.length > 0
    ) {
      if (isValidObject(audio.current) && audio.current.tagName === "AUDIO") {
        audio.current.addEventListener("timeupdate", function() {
          if (isValidObject(audio.current)) {
            setCurrentTime(Math.ceil(audio.current.currentTime));
          }
        });
        audio.current.addEventListener("durationchange", function() {
          if (
            isValidObject(audio.current) &&
            audio.current.tagName === "AUDIO" &&
            typeof audio.current.duration === "number" &&
            audio.current.duration !== Infinity
          ) {
            if (loading === true) {
              setLoading(false);
            }
            setDuration(Math.ceil(audio.current.duration));
          }
        });
      }
    }
    return () => {
      window.removeEventListener("timeupdate", function() {
        setCurrentTime(0);
      });

      window.removeEventListener("durationchange", function() {
        setDuration(0);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.src]);

  return (
    <React.Fragment>
      <audio
        allow="microphone"
        src={props.src ? props.src : ""}
        ref={audio}
        type="audio/mpeg"
        controls
        className="display-none"
      ></audio>
      <div
        className={`flex-center-children-vertically flex-justify-content-space-between inherit-parent-width ${props.className &&
          props.className}`}
        data-cy={props.dataCy || "audio-player"}
      >
        {loading === true ? (
          <svg
            data-cy="audio-loading"
            className="audio-player-loading-spinner audio-player-loading-spinner-size"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="audio-player-loading-spinner-path"
              fill="none"
              strokeWidth="6"
              strokeLinecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        ) : play === true ? (
          <div
            onClick={() => {
              audioPlayHandler();
            }}
            data-cy="pause-audio-button"
          >
            <PauseIcon
              color={
                props.src
                  ? props.theme === "dark"
                    ? "#FFFFFF"
                    : "#0249D6"
                  : "#C2C2C2"
              }
            />
          </div>
        ) : (
          <div
            onClick={() => {
              audioPlayHandler();
            }}
            data-cy="play-audio-button"
          >
            <PlayIcon
              color={
                props.src
                  ? props.theme === "dark"
                    ? "#FFFFFF"
                    : "#0249D6"
                  : "#C2C2C2"
              }
            />{" "}
          </div>
        )}

        {(props.src || !props.src === "") && (
          <input
            data-cy="audio-player-duration-track"
            disabled={props.loading || !props.src || props.src === ""}
            type="range"
            id="seek-slider"
            max={duration}
            className={`audio-duration-track-${props.theme} margin-left-medium margin-right-medium cursor-pointer`}
            value={currentTime}
            onChange={(event) => {
              audio.current.currentTime = event.target.value;
            }}
          />
        )}

        {(!props.src || props.src === "") && (
          <div
            className={` ${
              props.theme === "dark"
                ? "background-color-grey-C2C2C2"
                : "background-color-grey"
            }  height-2px inherit-parent-width margin-left-medium margin-right-medium`}
          />
        )}

        <span
          className={`${
            props.theme === "dark" ? "font-color-white" : "font-color-secondary"
          } font-size-medium white-space-nowWrap audio-duration-content-size font-family-gilroy-regular`}
          data-cy="audio-player-duration"
        >
          {currentTime > 0
            ? `${Math.floor(currentTime / 60)}:${
                Math.ceil(currentTime % 60) > 9
                  ? Math.ceil(currentTime % 60)
                  : "0" + Math.ceil(currentTime % 60)
              }`
            : duration > 0
            ? `${Math.floor(duration / 60)}:${
                Math.ceil(duration % 60) > 9
                  ? Math.ceil(duration % 60)
                  : "0" + Math.ceil(duration % 60)
              }`
            : "00:00"}
        </span>
        {props.closeOnClick && (
          <div
            className="padding-left-default padding-right-default cursor-pointer"
            onClick={props.closeOnClick}
          >
            <CloseIcon color="white" />
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default AudioPlayer;

const CloseIcon = (props) => {
  return (
    <svg
      width={props.width ? props.width : "16"}
      height={props.height ? props.height : "16"}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M4.16784 4.16784C4.22091 4.11464 4.28396 4.07243 4.35336 4.04363C4.42277 4.01482 4.49718 4 4.57233 4C4.64747 4 4.72188 4.01482 4.79129 4.04363C4.8607 4.07243 4.92374 4.11464 4.97681 4.16784L8.00015 7.19233L11.0235 4.16784C11.0766 4.11472 11.1397 4.07259 11.2091 4.04384C11.2785 4.0151 11.3529 4.0003 11.428 4.0003C11.5031 4.0003 11.5775 4.0151 11.6469 4.04384C11.7163 4.07259 11.7793 4.11472 11.8325 4.16784C11.8856 4.22096 11.9277 4.28402 11.9565 4.35342C11.9852 4.42282 12 4.49721 12 4.57233C12 4.64744 11.9852 4.72183 11.9565 4.79123C11.9277 4.86063 11.8856 4.92369 11.8325 4.97681L8.80797 8.00015L11.8325 11.0235C11.8856 11.0766 11.9277 11.1397 11.9565 11.2091C11.9852 11.2785 12 11.3529 12 11.428C12 11.5031 11.9852 11.5775 11.9565 11.6469C11.9277 11.7163 11.8856 11.7793 11.8325 11.8325C11.7793 11.8856 11.7163 11.9277 11.6469 11.9565C11.5775 11.9852 11.5031 12 11.428 12C11.3529 12 11.2785 11.9852 11.2091 11.9565C11.1397 11.9277 11.0766 11.8856 11.0235 11.8325L8.00015 8.80797L4.97681 11.8325C4.92369 11.8856 4.86063 11.9277 4.79123 11.9565C4.72183 11.9852 4.64744 12 4.57233 12C4.49721 12 4.42282 11.9852 4.35342 11.9565C4.28402 11.9277 4.22096 11.8856 4.16784 11.8325C4.11472 11.7793 4.07259 11.7163 4.04384 11.6469C4.0151 11.5775 4.0003 11.5031 4.0003 11.428C4.0003 11.3529 4.0151 11.2785 4.04384 11.2091C4.07259 11.1397 4.11472 11.0766 4.16784 11.0235L7.19233 8.00015L4.16784 4.97681C4.11464 4.92374 4.07243 4.8607 4.04363 4.79129C4.01482 4.72188 4 4.64747 4 4.57233C4 4.49718 4.01482 4.42277 4.04363 4.35336C4.07243 4.28396 4.11464 4.22091 4.16784 4.16784Z"
        fill={props.color ? props.color : "#404040"}
      />
    </svg>
  );
};
