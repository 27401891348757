import React from "react";
// import Chart from "chart.js/auto";
// import { CategoryScale, ArcElement, Tooltip, Legend } from "chart.js";
// import LineChart from "../../Components/Graphs/LineChart";
import { DoughnutChart } from "../../Components/Graphs/DoughnutChart";
import {
  stripeBarChartPlugin,
  stripeDoughnutChartPlugin,
} from "./stripePlugin";
import BarChart from "../../Components/Graphs/BarChart";
import "./Test.css";
// Chart.register(CategoryScale, ArcElement, Tooltip, Legend);

export default function Test() {
  const doughnutChartData = {
    labels: ["White", "Red", "Blue"],
    datasets: [
      {
        label: "# of Votes",
        data: [35, 30, 35],
        backgroundColor: ["#FFFFFF", "#3676D6", "#FF6C6C"],
        borderColor: ["#FFFFFF", "#3676D6", "#FF6C6C"],
        borderWidth: 1,
      },
    ],
  };

  const doughnutChartOptions = {
    plugins: {
      legend: {
        display: false,
      },

      tooltip: {
        enabled: false,
        callbacks: {
          label: function(context) {
            const label = context.label || "";
            const value = context.raw || "";
            return `${label}: ${value}`;
          },
        },
        position: "average",
        external: function(context) {
          const tooltip = context.tooltip;
          if (tooltip.opacity === 0) {
            return;
          }

          // Custom tooltip element
          let tooltipEl = document.getElementById("chartjs-tooltip");
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.backgroundColor = "rgba(0,0,0,0.7)";
            tooltipEl.style.color = "#fff";
            tooltipEl.style.padding = "5px";
            tooltipEl.style.borderRadius = "3px";
            tooltipEl.style.pointerEvents = "none";
            document.body.appendChild(tooltipEl);
          }

          tooltipEl.innerHTML = tooltip.body
            .map((bodyItem) => bodyItem.lines)
            .join("<br>");

          const chartPosition = context.chart.canvas.getBoundingClientRect();
          tooltipEl.style.left = `${chartPosition.left +
            window.pageXOffset +
            (tooltip.caretX - 10)}px`;
          tooltipEl.style.top = `${chartPosition.top +
            window.pageYOffset -
            tooltip.height -
            10}px`;
        },
      },
    },
    cutout: "50%",
    responsive: true,
    maintainAspectRatio: false,
  };

  const barChartData = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Dataset 1",
        backgroundColor: "white",
        borderColor: "black", // Optional: if you want a border color
        borderWidth: 1, // Optional: width of the border
        data: [65, 59, 80, 81, 56, 55, 40],
        barThickness: 24,
      },
    ],
  };

  const barChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "white",
        },
        border: {
          color: "red",
          width: 2,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          color: "white",
        },
        border: {
          color: "red",
          width: 2,
        },
      },
    },
  };

  return (
    <div className=" remaining-body-height-from-header-map  inherit-parent-width flex-align-items-center">
      <div className="display-flex inherit-parent-width">
        <BarChart
          data={barChartData}
          options={barChartOptions}
          plugins={[stripeBarChartPlugin]}
        />
        <DoughnutChart
          data={doughnutChartData}
          options={doughnutChartOptions}
          plugins={[stripeDoughnutChartPlugin]}
        />
      </div>
      <ProgressChart />
    </div>
  );
}

const ProgressChart = (second) => {
  const CornerLIcon = (props) => {
    return (
      <svg
        width={props.width || "6"}
        height={props.height || "6"}
        viewBox="0 0 6 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.42383 0.667969H0.278013V1.95442H5.42383V0.667969Z"
          fill="white"
        />
        <path
          d="M1.56447 5.81378V0.667969H0.278013V5.81378H1.56447Z"
          fill="white"
        />
      </svg>
    );
  };
  return (
    <div className="margin-larger display-flex inherit-parent-width">
      <div className="padding-bottom-large margin-right-large">
        <CornerLIcon width={16} height={16} />
      </div>
      {Array.from({ length: 100 }, (_, index) => (
        <div
          key={index}
          style={{
            height: "32px",
            transform: "rotate(45deg)",
          }}
          className={`margin-right-small padding-smaller margin-top-large margin-bottom-large ${
            index <= 40 ? "background-color-light-blue" : "background-white"
          }`}
        />
      ))}
      <div
        className="margin-left-large"
        style={{
          transform: "rotate(180deg)",
        }}
      >
        <CornerLIcon width={16} height={16} />
      </div>
    </div>
  );
};
