/**
 * @module AuthSaga
 */

import store from "../store";
import { authActionTypes } from "./saga";

/**
 * Login in using email and password from payload
 * @param {string} email
 * @param {string} password
 */
export function login(email, password) {
  store.dispatch({
    type: authActionTypes.LOGIN,
    payload: { email, password },
  });
}


export function setPasswordAction(data) {
  store.dispatch({
    type: authActionTypes.SET_PASSWORD,
    payload: data,
  });
}

export function fetchEmail(oobCode) {
  store.dispatch({
    type: authActionTypes.FETCH_ACCOUNT_EMAIL,
    payload: oobCode,
  });
}

export function checkProfileAction(data) {
  store.dispatch({
    type: authActionTypes.CHECK_PROFILE,
    payload: data,
  });
}

export function resetAuthTransaction() {
  store.dispatch({
    type: authActionTypes.CLEAR_TRANSACTION,
  });
}

/**
 * For logging out of user
 */
export function logout() {
  store.dispatch({
    type: authActionTypes.LOGOUT,
  });
}

/**
 * Update Access token
 * @param {string} accessToken
 */
export function addAccessToken(accessToken) {
  store.dispatch({
    type: authActionTypes.ADD_ACCESS_TOKEN,
    payload: {
      accessToken: accessToken,
    },
  });
}

/**
 * set theme
 * @param {string} theme
 */
export function setTheme(theme) {
  store.dispatch({
    type: authActionTypes.SET_THEME,
    payload: {
      theme: theme,
    },
  });
}

/**
 * Update Auth Info
 * @param {string} accessToken
 * @param {string} uid
 * @param {string} phoneNumber
 */
export function addAuthInfo(data) {
  store.dispatch({
    type: authActionTypes.ADD_AUTH_INFO,
    payload: data,
  });
}
