import "./UnsupportedDevice.css";
// import nintoLogo from "../../assets/ninto_logo_with_name.svg";
import unsupportedDeviceFallBack from "../../Assets/unsupportedDeviceFallBack.svg";
// import nintoLogoBlack from "../../assets/nintoLogoBlack.svg";
// import {
//   FacebookIcon,
//   InstagramIcon,
//   LinkedInIcon,
//   MediumIcon,
//   TwitterIcon,
//   WhatsappIcon
// } from "../../assets/assets";
// import playstoreDownloadButton from "../../Assets/playstoreDownloadButton.png";
// import appStoreDownloadButton from "../../Assets/appStoreDownloadButton.png";

export default function UnsupportedDevice(props) {
  return (
    <div className=" inherit-parent-height display-flex  flex-direction-column flex-justify-content-space-between">
      <div className=" display-flex flex-justify-content-center text-align-center inherit-parent-width inherit-parent-height padding-left-larger padding-right-larger unsupported-device-body">
        <div className="display-flex flex-direction-column flex-justify-content-center ">
          <img
            src={unsupportedDeviceFallBack}
            alt="unsupportedDeviceFallBack"
            className=" unsupported-device-fallback-image max-height-250px"
          />
        </div>

        <div className=" display-flex flex-justify-content-center flex-direction-column align-items-center  flex-align-items-center padding-left-larger padding-right-larger unsupported-device-content-area">
          {props.appName !== "clinics" && (
            <div className=" font-family-gilroy-regular unsupported-device-primary-content-web ">
              This <span className=" font-color-primary"> device </span> is{" "}
              <span className=" font-color-primary"> not supported </span>
            </div>
          )}

          {props.appName !== "clinics" && (
            <div className=" font-family-gilroy-regular unsupported-device-primary-content-phone ">
              Web <span className=" font-color-primary"> browser </span> is{" "}
              <span className=" font-color-primary"> not supported </span>
            </div>
          )}

          {props.appName !== "clinics" && (
            <div className=" padding-top-medium font-size-large unsupported-device-secondary-content-web">
              Please use Desktop web browser
            </div>
          )}

          {props.appName !== "clinics" && (
            <div className=" padding-top-medium font-size-large unsupported-device-secondary-content-phone">
              Please use Desktop web browser
            </div>
          )}

            <div className=" inherit-parent-width display-flex flex-justify-content-space-evenly padding-top-larger">
              <div>
                {/* <img
                  src={doctor_android_QR}
                  alt="doctor_android_QR"
                  className=" height-100px margin-top-large unsupported-device-qr-code"
                /> */}
                {/* <div>
                  <img
                    src={playstoreDownloadButton}
                    alt="playstoreDownloadButton"
                    className=" width-100px margin-top-medium app-download-button"
                  />
                </div> */}
              </div>

              <div>
                {/* <img
                  src={doctor_android_QR}
                  alt="doctor_android_QR"
                  className=" height-100px margin-top-large unsupported-device-qr-code"
                /> */}
                {/* <div>
                  <img
                    src={appStoreDownloadButton}
                    alt="playstoreDownloadButton"
                    className=" width-100px margin-top-medium app-download-button"
                  />
                </div> */}
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}
