import React, { useEffect, useRef, useState } from "react";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import { RotateIcon } from "../../Assets/assets";
import Button from "../Button/Button";

function CropperModal(props) {
  const [file, setFile] = useState("");
  const cropper = useRef(null);
  const [cropperReady, setCropperReady] = useState(false);
  // the file should be in URL.createObjectURL(file)
  useEffect(() => {
    if (props.file) {
      setFile(props.file);
    }
  }, [props.file]);

  async function cropHandler() {
    cropper.current
      .getCroppedCanvas(props.exportSettings ? props.exportSettings : {})
      .toBlob(
        (blob) => {
          props.OnBlobCreate(blob);
        },
        `image/${props.imageFormat ? props.imageFormat : "png"}`,
        1
      );
    setFile("");
    props.setFile();
    setCropperReady(false);
  }

  function handleCloseCropperModal() {
    setFile("");
    props.setFile();
    setCropperReady(false);
  }
  // handle the cropping data
  useEffect(() => {
    if (file) {
      var canvas = document.getElementById("imageData");
      var ctx = canvas.getContext("2d");
      var maxW = 1024;
      var maxH = 1024;
      var img = new Image();
      img.onload = function() {
        var iw = img.width;
        var ih = img.height;
        var scale = Math.min(maxW / iw, maxH / ih);
        var iwScaled = iw * scale;
        var ihScaled = ih * scale;
        canvas.width = iwScaled;
        canvas.height = ihScaled;
        ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
        cropper.current = new Cropper(canvas, {
          viewMode: 2,
          maxContainerWidth: 500,
          maxCanvasWidth: 100,
          maxCropBoxWidth: 20,
          background: false,
          borderRadius: 50,
          zoomable: true,
          dragMode: "move",
          responsive: true,
          guides: false,
          rotatable: true,
          ...(props.aspectRatio ? { aspectRatio: props.aspectRatio } : {}),
        });
        setCropperReady(true);
      };
      img.src = file;
    }
  }, [file, props.aspectRatio]);
  return (
    file && (
      <>
        <div
          data-cy="cropper-modal"
          className={`${props.className ? props.className : ""}  ${
            props.theme === "dark"
              ? "background-color-black"
              : "background-white"
          } inherit-parent-height inherit-parent-width flex-center-children-horizontally flex-direction-column`}
        >
          <div className="inherit-parent-height inherit-parent-width max-height-90-percentage padding-top-default">
            <canvas
              className="display-block max-width-100-percentage"
              id="imageData"
              data-cy="cropper-canvas"
            ></canvas>
          </div>
          <footer className="padding-larger inherit-parent-width flex-align-items-center flex-justify-content-space-evenly">
            <Button
              text="Cancel"
              variant="transparent"
              boxShadow={false}
              data-cy="crop-img-cancel-button"
              className={`font-size-larger width-fit-content ${
                props.theme === "dark" ? "font-color-white" : "font-color-dark"
              }`}
              onClick={() => {
                handleCloseCropperModal();
              }}
            />

            <div
              data-cy="crop-img-rotate-button"
              className="padding-top-medium"
              onClick={() => {
                cropper?.current?.rotate(90);
                cropper?.current?.zoomTo(0);
              }}
            >
              <RotateIcon color={props.theme === "dark" ? "white" : "black"} />
            </div>

            <Button
              text="Save"
              boxShadow={false}
              data-cy="crop-img-save-button"
              variant="transparent"
              className={`font-size-larger width-fit-content ${
                props.theme === "dark" ? "font-color-white" : "font-color-dark"
              }`}
              disabled={cropperReady === false}
              onClick={(event) => {
                if (
                  (cropper?.current?.getData(true)?.width &&
                    cropper?.current?.getData(true)?.width <= 50) ||
                  (cropper?.current?.getData(true)?.height &&
                    cropper?.current?.getData(true)?.height <= 50)
                ) {
                  return;
                }
                cropHandler(event);
              }}
            />
          </footer>
        </div>
      </>
    )
  );
}

export default CropperModal;
