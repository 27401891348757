const employeesReducer = (
  state = {
    loading: false,
    data: null,
    roles: null,
    captureSummary: null,
  },
  action
) => {
  switch (action.type) {
    case "SET_EMPLOYEES_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };

    case "SET_EMPLOYEES_DATA":
      return {
        ...state,
        data: action.payload.data,
      };

    case "SET_ROLES_DATA":
      return {
        ...state,
        roles: action.payload.data,
      };

    case "SET_CAPTURE_SUMMARY_DATA":
      return {
        ...state,
        captureSummary: action.payload.data,
      };

    case "RESET":
      return {
        loading: false,
        data: null,
        roles: null,
        captureSummary: null,
      };

    default:
      return state;
  }
};
export default employeesReducer;
