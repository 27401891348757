import store from "../store";
import { actionTypes } from "./saga";

/**
 * @module ProceduresSaga
 */

/**
 *
 * @param {object} data
 */
export function putProceduresData(data) {
  store.dispatch({
    type: actionTypes.ADD_PROCEDURES_DATA,
    payload: {
      data: data,
    },
  });
}

/**
 *
 * @param {object} procedureHistory
 */
export function putProceduresHistory(procedureHistory) {
  store.dispatch({
    type: actionTypes.PUT_PROCEDURES_HISTORY,
    payload: {
      procedureHistory: procedureHistory,
    },
  });
}

/**
 *
 * @param {object} locationId
 */
export function getProceduresHistory(locationId) {
  store.dispatch({
    type: actionTypes.GET_PROCEDURES_HISTORY,
    payload: {
      locationId: locationId,
    },
  });
}

/**
 *
 * @param {object} locationId
 */
export function getProceduresAssets(locationId, selectedProcedure, index) {
  store.dispatch({
    type: actionTypes.GET_PROCEDURES_ASSETS,
    payload: {
      locationId: locationId,
      selectedProcedure: selectedProcedure,
      index: index,
    },
  });
}

/**
 *
 * @param {object} data
 */
export function setSelectedProcedure(data) {
  store.dispatch({
    type: actionTypes.ADD_SELECTED_PROCEDURE,
    payload: {
      data: data,
    },
  });
}

/**
 *
 * @param {object} data
 */
export function createProcedurePreset(data) {
  store.dispatch({
    type: actionTypes.CREATE_PROCEDURE_PRESET,
    payload: {
      data: data,
    },
  });
}

/**
 *
 * @param {object} data
 */
export function editProcedurePreset(data) {
  store.dispatch({
    type: actionTypes.EDIT_PROCEDURE_PRESET,
    payload: {
      data: data,
    },
  });
}

export function setProceduresExportLoading(data) {
  store.dispatch({
    type: actionTypes.PUT_PROCEDURES_EXPORT_LOADING,
    payload: { loading: data },
  });
}
