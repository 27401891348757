import { all, put, takeEvery } from "@redux-saga/core/effects";
import { setErrorStatus } from "../status/action";
import { getAllTracks, getEmployeeData } from "../../Services/database";

export const actionTypes = {
  GET_TRACKS_DATA: "GET_TRACKS_DATA",
  CLEAR_TRACKS_DATA: "CLEAR_TRACKS_DATA",
};

function* getTracksWorker(action) {
  try {
    yield setTracksLoading(true);

    const employeeData = yield getEmployeeData(action.payload.phoneNumber);
    const data = yield getAllTracks(
      action.payload.phoneNumber,
      action.payload.timestamp
    );

    yield put({
      type: "SET_TRACKS_DATA",
      payload: data,
    });
    yield put({
      type: "SET_SELECTED_EMPLOYEE",
      payload: employeeData,
    });

    yield setTracksLoading(false);
  } catch (error) {
    yield setTracksLoading(false);
    setErrorStatus(error);
  }
}

function* clearTracksWorker() {
  yield put({
    type: "CLEAR_TRACKS",
  });
}

export default function* tracksWatcher() {
  yield all([
    takeEvery("GET_TRACKS_DATA", getTracksWorker),
    takeEvery("CLEAR_TRACKS_DATA", clearTracksWorker),
  ]);
}

function* setTracksLoading(bool) {
  yield put({
    type: "SET_TRACKS_LOADING",
    payload: {
      loading: bool,
    },
  });
}
