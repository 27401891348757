import { signOut, signInWithEmailAndPassword, sendPasswordResetEmail, verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import LogRocket from "logrocket";
import { useEffect, useRef } from "react";
import { auth } from "./firebase";
import { addAuthInfo } from "../Redux/authentication/actions";

export async function loginWithEmailAndPassword(data) {
  return await signInWithEmailAndPassword(auth, data.email, data.password);
}

export function useAuthSubscriber(onAuthStateChange) {
  const isAuth = useRef(null);

  useEffect(() => {
    auth.onIdTokenChanged(async (user) => {
      if (user) {
        const accessToken = await user.getIdToken();
        const idTokenResult = await user.getIdTokenResult();
        const { claims } = idTokenResult;
        addAuthInfo({
          accessToken: accessToken,
          refreshToken: user?.stsTokenManager?.refreshToken,
          uid: user.uid,
          email: user.email,
        });
        if (isAuth.current !== true) {
          isAuth.current = true;
          LogRocket.identify(user.uid, {
            email: user.email,
          });
          onAuthStateChange({ isAuth: isAuth.current, claims: claims });
        }
      } else if (!user && isAuth.current !== false) {
        logout();
        isAuth.current = false;
        onAuthStateChange({ isAuth: isAuth.current, claims: null });
      }
    });
    // eslint-disable-next-line
  }, []);
  return isAuth.current;
}

export const logout = async () => {
  await signOut(auth);
};

export function getCurrentUser() {
  return auth.currentUser;
}

export async function sendEmailPasswordResetLink(email){
  return sendPasswordResetEmail(auth, email)
}

export const checkPasswordResetCode = async (actionCode) => {
  const email = await verifyPasswordResetCode(auth, actionCode);
  return email; 
}

export const resetAccountPassword = async (actionCode, newPassword) => {
  await confirmPasswordReset(auth, actionCode, newPassword);
}


