import vmsg from "vmsg";
import { setErrorStatus } from "../Redux/status/action";

const recorder = new vmsg.Recorder({
  wasmURL: "https://unpkg.com/vmsg@0.3.0/vmsg.wasm",
});

export async function startRecording() {
  try {
    await recorder?.initAudio();
    await recorder?.initWorker();
    await recorder?.startRecording();
  } catch (error) {
    if (error.code === 8 && error.name === "NotFoundError") {
      setErrorStatus({
        code: "custom",
        message: "Microphone is not detected",
      });
      return false;
    } else {
      setErrorStatus(error);
      return false;
    }
  }
}

export async function saveRecording() {
  const blob = await recorder?.stopRecording();
  return blob;
}

export default function useVoiceRecorder() {
  return {
    startRecording: () => startRecording(),
    saveRecording: () => saveRecording(),
  };
}
