import { all, put, takeEvery } from "@redux-saga/core/effects";
import { setErrorStatus, setSuccessStatus } from "../status/action";
import {
  assignProcedureToSensor,
  createNewSensor,
  deleteLinkedSensorProcedures,
} from "../../Services/database";

export const actionTypes = {
  PUT_SENSORS_DATA: "PUT_SENSORS_DATA",
  CREATE_SENSOR: "CREATE_SENSOR",
  ASSIGN_PROCEDURE_TO_SENSOR: "ASSIGN_PROCEDURE_TO_SENSOR",
  DELETE_LINKED_SENSOR_PROCEDURE: "DELETE_LINKED_SENSOR_PROCEDURE",
};

function* setSensorsDataWorker(action) {
  yield put({
    type: "SET_SENSORS_DATA",
    payload: {
      data: action.payload.data,
    },
  });
}

function* createSensorWorker(action) {
  try {
    yield setSensorsLoading(true);
    yield createNewSensor(action.payload.data);
    yield setSuccessStatus("Sensor created successfully");
    yield setSensorsLoading(false);
  } catch (error) {
    yield setSensorsLoading(false);
    setErrorStatus(error);
  }
}

function* assignProcedureToSensorWorker(action) {
  try {
    yield setSensorsLoading(true);
    yield assignProcedureToSensor(action.payload.sensorId, action.payload.data);
    yield setSuccessStatus("Procedure assigned successfully");
    yield setSensorsLoading(false);
  } catch (error) {
    yield setSensorsLoading(false);
    setErrorStatus(error);
  }
}
function* deleteProcedureFormSensorWorker(action) {
  try {
    yield setSensorsLoading(true);
    yield deleteLinkedSensorProcedures(
      action.payload.sensorId,
      action.payload.data
    );
    yield setSuccessStatus("Procedure deleted successfully");
    yield setSensorsLoading(false);
  } catch (error) {
    yield setSensorsLoading(false);
    setErrorStatus(error);
  }
}

export default function* sensorsWatcher() {
  yield all([
    takeEvery("PUT_SENSORS_DATA", setSensorsDataWorker),
    takeEvery("CREATE_SENSOR", createSensorWorker),
    takeEvery("ASSIGN_PROCEDURE_TO_SENSOR", assignProcedureToSensorWorker),
    takeEvery(
      "DELETE_LINKED_SENSOR_PROCEDURE",
      deleteProcedureFormSensorWorker
    ),
  ]);
}

function* setSensorsLoading(bool) {
  yield put({
    type: "SET_SENSORS_LOADING",
    payload: {
      loading: bool,
    },
  });
}
