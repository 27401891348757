import React, { useState } from "react";
import Button from "../../Components/Button/Button";
import { ProcedureCard } from "./Cards";
import EditProcedure from "./EditProcedure";
import NoSelectionFallBack from "../../Components/NoSelectionFallback/NoSelectionFallback";
import { connect } from "react-redux";
import { isValidObject } from "../../Services/validators";
import CreateProcedure from "./CreateProcedure";
import {
  createProcedurePreset,
  editProcedurePreset,
} from "../../Redux/procedures/action";
import AssignProcedures from "./AssignProcedures";
import {
  assignProcedureToEmployee,
  createEmployee,
  deleteProcedureLinkedToEmployee,
} from "../../Redux/employees/action";
import {
  assignProcedureToSensor,
  createSensor,
  deleteProcedureLinkedToSensor,
} from "../../Redux/sensors/action";
// import Modal from "../../Components/Modal/Modal";
// import CreateModal from "./CreateModal";

function Procedures(props) {
  const [activeState, setActiveState] = useState(null);
  const [procedureType, setProcedureType] = useState(null);
  const [selectedPreset, setSelectedPreset] = useState(null);
  return (
    !props.show && (
      <div className="display-flex remaining-body-height-from-header  ">
        <div className="width-70-percentage  inherit-parent-height  border-right-1px-quaternary  ">
          <div className="padding-top-medium padding-right-medium inherit-parent-height">
            <div className=" display-flex flex-justify-content-space-between padding-bottom-default">
              <div className="display-flex flex-justify-content-center flex-direction-column padding-left-large">
                <div className="font-size-larger  ">
                  Presets
                </div>
                <div>You can assign or edit the following procedures</div>
              </div>
              <div>
                <Button
                  data-cy={"create-employee-or-sensor"}
                  // loading={props.auth.loading}
                  type="Button"
                  className=" min-width-100px"
                  text="Create"
                  onClick={() => {
                    setActiveState("create");
                    setProcedureType(null);
                    setSelectedPreset(null);
                  }}
                  boxShadow={false}
                />
              </div>
            </div>
            <div
              data-cy="procedures-list"
              className="remaining-height-for-procedures overflow-auto hide-scrollbar "
            >
              {isValidObject(props.procedures.data) &&
                Object.values(props.procedures.data).map(
                  (data, index) =>
                    (data.procedureType === "employee" ||
                      data.procedureType === "sensor" ||
                      data.procedureType === "logistics") && (
                      <div
                        key={index}
                        className="padding-top-small padding-bottom-small"
                      >
                        <ProcedureCard
                          data={data}
                          selected={data.documentId === selectedPreset}
                          activeState={activeState}
                          assignEmployeeOnClick={() => {
                            setActiveState("assign");
                            setProcedureType("employee");
                            setSelectedPreset(data.documentId);
                          }}
                          assignLogisticOnClick={() => {
                            setActiveState("assign");
                            setProcedureType("logistics");
                            setSelectedPreset(data.documentId);
                          }}
                          assignSensorOnClick={() => {
                            setActiveState("assign");
                            setProcedureType("sensor");
                            setSelectedPreset(data.documentId);
                          }}
                          editOnClick={() => {
                            setActiveState("edit");
                            setSelectedPreset(data.documentId);
                          }}
                          theme={props.theme}
                        />
                      </div>
                    )
                )}
            </div>
          </div>
        </div>
        <div className="width-30-percentage inherit-parent-height">
          <div className="inherit-parent-height">
            {activeState === null && (
              <div className="inherit-parent-height padding-larger">
                <NoSelectionFallBack
                  title="PRESET SELECTION"
                  description="Select any one of the preset in the left side to edit or assign on the right side."
                />
              </div>
            )}

            {activeState === "create" && !procedureType && (
              <div className="inherit-parent-height padding-larger">
                <NoSelectionFallBack
                  title="CREATE PROCEDURES"
                  description="You can create new procedures for employees and sensors"
                  create={true}
                  userOnClick={() => {
                    setProcedureType("employee");
                  }}
                  sensorOnClick={() => {
                    setProcedureType("sensor");
                  }}
                  logisticOnClick={() => {
                    setProcedureType("logistics");
                  }}
                />
              </div>
            )}

            {activeState === "create" && procedureType && (
              <CreateProcedure
                procedureType={procedureType}
                onProcedureCreate={(data) => {
                  props.createProcedurePreset(data);
                }}
                backArrowOnClick={() => {
                  setProcedureType(null);
                }}
                procedurePresets={props.procedures.data}
                theme={props.theme}
              />
            )}

            {activeState === "edit" && selectedPreset && (
              <EditProcedure
                data={props.procedures.data[selectedPreset]}
                onProcedureEdit={(data) => {
                  props.editProcedurePreset(data);
                }}
                procedureType={procedureType}
                backArrowOnClick={() => {
                  setActiveState(null);
                  setSelectedPreset(null);
                }}
                theme={props.theme}
              />
            )}

            {activeState === "assign" && selectedPreset && (
              <AssignProcedures
                procedureType={procedureType}
                data={props.procedures.data[selectedPreset]}
                employees={props.employees}
                sensors={props.sensors}
                hits={props.hits}
                removeAlgoliaSearch={() => {
                  props.removeAlgoliaSearch();
                }}
                algoliaSearchKey={props.algoliaSearchKey}
                onProcedureEdit={(data) => {
                  props.assignProcedurePreset(data);
                }}
                createEmployee={(data) => {
                  props.createEmployee(data);
                }}
                createSensor={(data) => {
                  props.createSensor(data);
                }}
                deleteLocation={(employeeId, data) => {
                  props.deleteAssignedLocation(employeeId, data);
                }}
                deleteSensorProcedure={(employeeId, data) => {
                  props.deleteProcedureLinkedToSensor(employeeId, data);
                }}
                assignProcedure={(employeeId, locations, data) => {
                  if (
                    procedureType === "employee" ||
                    procedureType === "logistics"
                  ) {
                    props.assignProcedureToEmployee(
                      employeeId,
                      locations,
                      data
                    );
                  } else {
                    props.assignProcedureToSensor(employeeId, locations, data);
                  }
                }}
                backArrowOnClick={() => {
                  setActiveState(null);
                  setSelectedPreset(null);
                }}
                theme={props.theme}
              />
            )}
          </div>
        </div>
        {/* <Modal
          show={activeState === "create"}
          canIgnore={true}
          onClose={() => {
            setActiveState(null);
          }}
          background="background-transparent"
          width="width-70-percentage"
          boxShadow="box-shadow-none"
          height="height-60-percentage"
        >
          <CreateModal />
        </Modal> */}
      </div>
    )
  );
}

const mapStateToProps = function(state) {
  return {
    procedures: state.procedures,
    employees: state.employees,
    sensors: state.sensors,
  };
};

const mapDispatchToProps = function() {
  return {
    createProcedurePreset: (data) => createProcedurePreset(data),
    assignProcedureToEmployee: (employeeId, data, current) =>
      assignProcedureToEmployee(employeeId, data, current),
    assignProcedureToSensor: (employeeId, data) =>
      assignProcedureToSensor(employeeId, data),
    createEmployee: (data) => createEmployee(data),
    createSensor: (data) => createSensor(data),
    deleteAssignedLocation: (employeeId, data) =>
      deleteProcedureLinkedToEmployee(employeeId, data),
    deleteProcedureLinkedToSensor: (employeeId, data) =>
      deleteProcedureLinkedToSensor(employeeId, data),
    editProcedurePreset: (data) => editProcedurePreset(data),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Procedures);
