import store from "../store";
import { actionTypes } from "./saga";

export function createPicture(data, asset) {
  store.dispatch({
    type: actionTypes.CREATE_PICTURE,
    payload: { data: data, asset: asset },
  });
}

export function editPicture(data, asset, documentId) {
  store.dispatch({
    type: actionTypes.EDIT_PICTURE,
    payload: { data: data, asset: asset, documentId: documentId },
  });
}

export function createDevice(data) {
  store.dispatch({
    type: actionTypes.CREATE_DEVICE,
    payload: { data: data },
  });
}

export function putReferencePicture(data) {
  store.dispatch({
    type: actionTypes.PUT_REFERENCE_PICTURE,
    payload: { data: data },
  });
}

export function getReferencePictureAsset(documentId) {
  store.dispatch({
    type: actionTypes.GET_REFERENCE_PICTURE_ASSETS,
    payload: { documentId: documentId },
  });
}

export function getSelectedEmployeeCapture(documentId) {
  store.dispatch({
    type: actionTypes.GET_EMPLOYEE_CAPTURED_PICTURE,
    payload: { documentId: documentId },
  });
}

export function getPictureStatus(employeeId) {
  store.dispatch({
    type: actionTypes.GET_PICTURE_STATUS,
    payload: { employeeId: employeeId },
  });
}

export function putDevices(data) {
  store.dispatch({
    type: actionTypes.PUT_DEVICES,
    payload: { data: data },
  });
}

export function retryPictureUploadToDevices(documentId) {
  store.dispatch({
    type: actionTypes.RETRY_PICTURE_UPLOAD_TO_DEVICES,
    payload: { documentId: documentId },
  });
}
