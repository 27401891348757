export const complaintsCategory = [
  {
    english: "Restroom is not open",
    tamil: "கழிப்பறை திறக்கப்படவில்லை",
  },
  {
    english: "No water supply",
    tamil: "தண்ணீர் வரவில்லை",
  },
  {
    english: "Lights not working",
    tamil: "விளக்குகள் எரியவில்லை",
  },
  {
    english: "Waterlogged in the drainage",
    tamil: "கழிவு நீர் குழாயில் அடைப்பு",
  },
  {
    english: "Broken doors, latches",
    tamil: "உடைந்த கதவுகள், தாழ்பாள்கள்",
  },
  {
    english: "Lack of security measures",
    tamil: "பாதுகாப்பு குறைபாடு (பாதுகாப்புஇல்லாத கழிவறை)",
  },
  {
    english: "Janitors are not on duty",
    tamil: "பராமரிப்பாளர் பணியில் இல்லை",
  },
  {
    english: "Unsanitary restroom",
    tamil: "சுத்தமில்லாத கழிவறை",
  },
  {
    english: "Fees are charged",
    tamil: "கட்டணம் வசூலிக்கப்படுகிறது",
  },
  {
    english: "Unhygienic environment",
    tamil: "சுற்றுபுறம் தூய்மையாக இல்லை",
  },
];
