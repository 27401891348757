import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { dateAndTimeConverter } from "../../Utils/constants";
import Modal from "../../Components/Modal/Modal";

function AddDevice(props) {
  const [captures, setCaptures] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    setCaptures(Object.values(props.surveillance.captures));
  }, [props.surveillance]);

  return (
    <div className="remaining-body-height-from-header inherit-parent-width display-flex flex-direction-column  flex-align-items-center overflow-scroll ">
      {captures
        ?.sort((first, second) => {
          return second.createdAt - first.createdAt;
        })
        .map((data) => (
          <div
            style={{
              width: "500px",
              height: "100px",
              "background-color":
                props.theme === "dark" ? "#434343" : "#f2f2f2",
            }}
            className="  margin-top-default margin-bottom-default display-flex bac"
          >
            <img
              src={props.surveillance?.reference?.[data.pictureId]?.fileSrc}
              alt="reference"
              height={100}
              width={100}
              onClick={() => {
                setPreviewImage(
                  props.surveillance?.reference?.[data.pictureId]?.fileSrc
                );
              }}
            />
            <div
              style={{ width: "300px", height: "100px" }}
              className=" padding-default font-size-default"
            >
              <div> Name: {data.name} </div>
              <div>
                {" "}
                Location: {data.location.PCTNo}-{data.location.address}{" "}
              </div>
              <div>
                {" "}
                Date: {dateAndTimeConverter(data.createdAt, "secondDate")}{" "}
              </div>
              <div> Time: {dateAndTimeConverter(data.createdAt, "Time")} </div>
            </div>
            <img
              src={data.fileSrc}
              alt="capture"
              height={100}
              width={100}
              onClick={() => {
                setPreviewImage(data.fileSrc);
              }}
            />
          </div>
        ))}

      <Modal
        show={typeof previewImage === "string"}
        canIgnore={true}
        onClose={() => {
          setPreviewImage(null);
        }}
        borderRadius="false"
        boxShadow="false"
      >
        <div
          className="display-flex flex-justify-content-center"
          data-cy={"view-image-modal"}
        >
          <img src={previewImage} height={500} alt="preview" />
        </div>
      </Modal>
      {document.addEventListener("keydown", function(event) {
        if (event.key === "Escape" && previewImage !== null) {
          setPreviewImage(null);
        }
      })}
    </div>
  );
}

const mapStateToProps = (state) => ({
  surveillance: state.surveillance,
  locations: state.locations,
});

const mapDispatchToProps = function() {
  return {
    // createDevice: (data) => createDevice(data),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDevice);
